.page-checkout-payment {
  .checkout-help {
    text-align: center;
    font-size: 150%;
    margin-bottom: 50px;
  }
  img[src="/sites/all/libraries/atos/logo/CLEF.gif"] {
    display: none;
  }
  input[type="IMAGE"] {
    height: 78px;
  }
}

.page-node-done {
  .webform-confirmation,
  .links {
    a {
      color: $colorBlue1;
    }
  }
}
