.node-type-etablissement {
  #page-title {
    display: none;
  }

  h2 {
    margin: 47px 0;
    font-family: $fontMontserrat;
    font-size: 2.6rem;
    font-weight: bold;
    color: $colorBlack;

    &:first-of-type {
      font-family: $fontDroidSans;
      font-size: 3.8rem;
      color: $colorBlue;
    }

    &#informations {
      margin-top: 0;

      @include sm {
        margin-top: 47px;
      }
    }
  }

  h3 {
    margin: 20px 0;
  }

  .field-etablissement-adresses > .wrapper.field-adresse-services-lies {
    @extend h2;
  }

  .wrapper.field-adresse-services-lies {
    display: block;
  }

  .field-adresse-services-lies {
    display: inline-block;
  }

  .type-video {
    margin: 45px 0;
  }

  .group-right {
    > div {
      @extend %clearfix;
    }
  }

  div.field-adresse-info-complement {
    margin-top: 3rem;
  }

  #rogeraccess-button {
    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3rem;

    &+.field-etablissement-adresses .field-adresse-services-lies{
      margin-top: 0;
    }
  }
}

.field-etablissement-lat-long,
.field-partenaire-lat-long {
  float: left;
  width: 44.44%;
  padding-right: 10px;

  iframe {
    width: 100%;
    height: 400px;

    @include sm {
      height: 352px;
    }
  }

  @include sm {
    width: 100%;
    padding: 0;
    margin-bottom: 36px;
  }
}

.group-addresses {
  float: left;
  width: 55.56%;
  padding-left: 10px;
  font-size: 1.7rem;
  color: $colorBlack;

  @include sm {
    padding: 0;
    width: 100%;
    font-size: 2.7rem;
  }

  @include xs {
    font-size: 1.7rem;
  }

  .field-label {
    display: block;
    font-family: $fontDroidSans;
    font-size: 2.5rem;
    font-weight: bold;
    color: $colorBlack;

    + div {
      margin: 18px 0 26px;
    }

    @include sm {
      font-size: 3.5rem;
    }

    @include xs {
      font-size: 2.5rem;
    }
  }
}

.acceo {
  margin: 58px 0;
  padding: 60px 52px 35px;
  background-color: $colorLightGray3;
  font-size: 2.5rem;
  color: $colorBlack;
  @extend %clearfix;

  @include sm {
    font-size: 3.5rem;
  }

  @include xs {
    font-size: 2.5rem;
  }

  img {
    display: block;
    float: left;
    width: 79px;

    @include xs {
      margin: 0 auto;
      float: none;
      margin-bottom: 20px;
    }
  }

  .acceo-texts {
    float: left;
    width: calc(100% - 79px);
    padding-left: 60px;

    @include xs {
      width: 100%;
      padding: 0;
    }
  }

  .title {
    font-family: $fontDroidSans;
    font-size: 3.2rem;
    font-weight: bold;
    color: $colorBlue;
    margin-bottom: 24px;

    @include sm {
      font-size: 4.1rem;
    }

    @include xs {
      font-size: 3.2rem;
    }
  }

  a {
    @extend %yellow-button;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 15px 60px;
    margin-top: 24px;
  }
}

.wrapper.field-adresse-adresse.inline,
.wrapper.field-adresse-tel1.inline,
.wrapper.field-adresse-email.inline,
.wrapper.field-comite-adresse,
.group-phones,
.wrapper.field-comite-email {
  display: flex;
  align-items: center;
  height: 126px;

  @include sm {
    height: 150px;
  }

  @include xs {
    flex-wrap: wrap;
    height: auto;
  }

  .field-label {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 33.33%;
    height: 100%;
    color: $colorBlue;
    font-family: $fontDroidSans;
    font-weight: bold;
    font-size: 2.5rem;
    background-color: $colorLightGray4;

    @include sm {
      width: 40%;
      font-size: 3.5rem;
    }

    @include xs {
      width: 100%;
      font-size: 2.5rem;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 66.66%;
    height: 100%;
    padding: 20px;
    border: 2px solid $colorLightGray4;
    border-width: 2px 2px 2px 0;
    font-size: 2.5rem;
    color: $colorBlack;

    @include sm {
      width: 60%;
      font-size: 3.5rem;
    }

    @include xs {
      border-width: 2px;
      width: 100%;
      font-size: 2.5rem;
    }
  }
}

.wrapper.field-adresse-tel1.inline {
  .field-label {
    background-color: $colorLightGray11;
  }

  > div {
    border-width: 0 2px 0 0;

    @include xs {
      border-width: 0 2px 2px;
    }
  }
}

.wrapper.field-adresse-info-complement.inline {
  .field-label {
    display: block;
    margin: 47px 0;
    font-family: $fontMontserrat;
    font-size: 2.6rem;
    font-weight: bold;
    color: $colorBlack;
  }
}

.group-phones {
  > div {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 33.33%;
    height: 100%;
    color: $colorBlue;
    font-family: $fontDroidSans;
    font-weight: bold;
    font-size: 2.5rem;
    background-color: $colorLightGray11;
    border: 0;

    @include sm {
      width: 40%;
      font-size: 3.5rem;
    }

    @include xs {
      width: 100%;
      font-size: 2.5rem;
    }
  }

  .wrapper.field-comite-tel1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 66.66%;
    height: 100%;
    padding: 20px;
    border: 2px solid $colorLightGray4;
    border-width: 0 2px 0 0;
    font-size: 2.5rem;
    color: $colorBlack;

    @include sm {
      width: 60%;
      font-size: 3.5rem;
    }

    @include xs {
      border-width: 0 2px 2px;
      width: 100%;
      font-size: 2.5rem;
    }
  }
}
