/* BLOC LES ACTIONS */
#block-ladapt-block-actions {
  clear: left;
  width: 100%;
}

#block-ladapt-block-actions h2 {
  background: url(../../images/line.png) repeat-x center;
  text-align: center;
}

#block-ladapt-block-actions h2 span {
  background: #f5f4f0;
  color: #056d8b;
  font-family: 'dinregular';
  font-size: 25px;
  line-height: 28px;
  padding: 0 20px;
  text-transform: uppercase;
}

#block-ladapt-block-actions .control-nav {
  float: left;
  width: 180px;
}

#block-ladapt-block-actions .control-nav li {
  background: #fff;
  border-bottom: 1px solid #f6f6f6;
  color: #056d8b;
  font-size: 20px;
  height: 62px;
  line-height: 60px;
  padding-left: 15px;
  text-transform: uppercase;
}

#block-ladapt-block-actions .control-nav li:hover {
  background: #056d8b;
  color: #fff;
  cursor: pointer;
}

#block-ladapt-block-actions .control-nav li.flex-active {
  background: #056d8b;
  color: #fff;
  position: relative;
}

#block-ladapt-block-actions .control-nav li.flex-active::after {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #04627D;
  content: " ";
  height: 0;
  left: 100%;
  pointer-events: none;
  position: absolute;
  top: 26px;
  z-index: 10;
  width: 0;
}

#block-ladapt-block-actions .flexslider {
  float: left;
  width: 590px;
}

/*
 Petit patch necessaire pour que le js qui
 genere le bg-gradient arrive a calculer la bonne hauteur.
*/
body.js #block-ladapt-block-actions .slides {
  height: 313px;
  overflow: hidden;
}

#block-ladapt-block-actions figure {
  height: 313px;
  position: relative;
}

#block-ladapt-block-actions figcaption {
  background: rgba(255, 255, 255, 0.80);
  bottom: 0;
  height: 100px;
  padding: 15px;
  position: absolute;
  width: 100%;
}

#block-ladapt-block-actions figcaption strong {
  color: #056d8b;
  display: block;
  font-size: 19px;
  text-transform: uppercase;
}

#block-ladapt-block-actions figcaption span {
  color: black;
  font-size: 20px;
}

#block-ladapt-block-actions figcaption .more {
  display: block;
  text-align: right;
}

#block-ladapt-block-actions figcaption .more span {
  background: #fff;
  border-radius: 3px;
  color: #056d8b;
  font-size: 13px;
  padding: 7px 15px;
}

#block-ladapt-block-actions figure:hover .more span {
  text-decoration: underline;
}


/* Theme du pager */
#block-ladapt-block-actions .flex-control-paging {
  bottom: -25px;
}
#block-ladapt-block-actions .flex-control-paging li a.flex-active {
  background-color: #1e7530;
}
#block-ladapt-block-actions .flex-control-paging li a {
  background-color: #404040;
}



/* #block-views-territoires-block */
#block-views-territoires-block {
  position: relative;
}
#block-views-territoires-block h2.block__title {
  background: #fdfcfb;
  background: -moz-linear-gradient(top,  #fdfcfb 0%, #f2efe6 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fdfcfb), color-stop(100%,#f2efe6));
  background: -webkit-linear-gradient(top,  #fdfcfb 0%,#f2efe6 100%);
  background: -o-linear-gradient(top,  #fdfcfb 0%,#f2efe6 100%);
  background: -ms-linear-gradient(top,  #fdfcfb 0%,#f2efe6 100%);
  background: linear-gradient(to bottom,  #fdfcfb 0%,#f2efe6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdfcfb', endColorstr='#f2efe6',GradientType=0 );
  background-image: url(../../images/actu-accueil-picto.png);
  background-repeat: no-repeat;
  background-position: 10px 5px;
  padding: 5px 10px 5px 56px;
  margin-bottom: 0;
  border: 1px solid #e8e4d7;
}

#block-views-territoires-block .slides {
  list-style-type: none;
  padding: 0;
}
#block-views-territoires-block .slides li {
  padding: 0;
  margin: 0;
}
#block-views-territoires-block figure {
  position: relative;
  background-color: #f9f8f6;
  border: 1px solid #e8e4d7;
  padding: 10px;
  margin: 0;
}
#block-views-territoires-block figure a {
  color: #d80663;
  text-decoration: none;
  font-weight: bold;
}
#block-views-territoires-block figcaption {
  font-size: 0.9em;
  padding-bottom: 20px;
}
#block-views-territoires-block .flex-control-nav {
  display: none;
}
#block-views-territoires-block .flex-direction-nav a.flex-prev {
  top: 27px;
  left: 690px;
  opacity: 1;
}
#block-views-territoires-block .flex-direction-nav a.flex-next {
  top: 27px;
  right: 10px;
  opacity: 1;
}




/** Bloc de recherche */
#block-ladapt-block-recherche,
#block-ladapt-block-recherche-mini {
  position: absolute;
  right: 68px;
  top: 225px;
  font-family: 'dinregular';
  text-transform: uppercase;
  background: white;
  padding: 10px 0;
  width: 230px;
  text-align: center;
  border-radius: 5px;
}
#block-ladapt-block-recherche-mini {
  position: relative;
  top: 10px;
  left: 720px;
}
#block-ladapt-block-recherche.opened {
  height: 54px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
#block-ladapt-block-recherche .button,
#block-ladapt-block-recherche-mini .button {
  display: block;
  background: none;
  padding: 0;
  height: 30px;
}
#block-ladapt-block-recherche .button a,
#block-ladapt-block-recherche-mini .button a {
  background: url("../../images/loupe-grise.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 40px;
  margin: 0 0 0 30px;
  padding: 0 0 0 54px;
  text-align: left;
  text-decoration: none;
  width: 200px;
}
#block-ladapt-block-recherche-mini span,
#block-ladapt-block-recherche .button span {
  background: none;
  display: block;
  font-size: 70%;
  font-weight: normal;
  letter-spacing: 0;
  margin-left: 0;
  padding: 0;
  text-align: left;
  text-transform: lowercase;
}
#block-ladapt-block-recherche .button span:first-letter {
  text-transform: uppercase;
}


#block-ladapt-block-recherche-header {
  font-family: 'dinregular';
  text-transform: uppercase;
  background: white;
  padding: 10px 0;
  width: 235px;
  text-align: center;
  border-radius: 5px;
}
#block-ladapt-block-recherche-header .button {
  display: block;
  background: none;
  padding: 0;
  height: 30px;
}
#block-ladapt-block-recherche-header .button a {
  background: url("../../images/loupe-grise.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 40px;
  margin: 0 0 0 30px;
  padding: 4px 0 0 54px;
  text-align: left;
  text-decoration: none;
  width: 200px;
}


#block-ladapt-block-territoire-landing-departements {
  border-top: 1px solid #c1baa2;
  border-bottom: 1px solid #c1baa2;
  margin: 10px 0;
  padding: 10px 0;
}
#block-ladapt-block-territoire-landing-departements:after {
  content: "";
  display: table;
  clear: both;
}

#block-ladapt-block-territoire-landing-departements h2.block__title {
  color: #a05d13;
  font-size: 1.2em;
  float: left;
  margin: 0 8px 0 0;
  padding: 0 0 0 24px;
  background: transparent url("../../images/departement-pin.png") no-repeat scroll 0 2px;
  font-weight: normal;
  font-family: 'dinregular';
  width: 162px;
}
#block-ladapt-block-territoire-landing-departements .block__content {
  float: left;
  width: 590px;
  font-size: 0.8em;
  margin: 8px 0 0 0;
}
#block-ladapt-block-territoire-landing-departements .block__content a {
  text-decoration: none;
  color: #000;
}
