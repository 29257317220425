.page-entreprises-engageeshtml {
  .row {

    max-width: 910px;
    margin: 0 auto;

    background-color: $colorWhite;
    box-shadow: 0 0 18px rgba($colorBlack, 0.31);
    margin-bottom: 33px;
    @extend %clearfix;

    @include sm {
      margin-bottom: 40px;
    }

    .image-principale {
      width: 44%;
      float: left;
      font-size: 0;

      @include sm {
        width: 50%;
      }

      @include xs {
        width: 100%;
        float: none;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .titre-contenu {
      margin: 0;
      padding: 40px 20px 25px;
      color: $colorBlue1;
      font-size: 2.7rem;
      font-weight: bold;
      float: left;
      width: 56%;

      @include sm {
        width: 50%;
        padding: 13px 20px 25px;
        font-size: 3.7rem;
        word-wrap: break-word;
      }

      @include xs {
        width: 100%;
        float: none;
        font-size: 2.7rem;
      }
    }

    .chapo-contenu {
      padding: 0 20px 20px;
      color: $colorBlack;
      font-size: 1.8rem;
      float: right;
      width: 56%;

      @include sm {
        width: 50%;
        font-size: 2.8rem;
        word-wrap: break-word;
      }

      @include xs {
        width: 100%;
        float: none;
        font-size: 1.8rem;
      }
    }
  }
}