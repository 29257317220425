.page-accueil,
.page-home {
  #page-title {
    display: none;
  }

  #main-content {
    padding-bottom: 0;
  }

  #main-wrapper .section {
    max-width: 100%;
    padding: 0;
  }

  .page-content-title {
    display: none;
  }


  .mea-slider-home {
    position: relative;

    h2 {
      display: none;
    }
  }

  .slider-controls {
    width: 650px;
    min-height: 205px;
    position: absolute;
    bottom: -136px;
    left: calc((#{$container-width} / 12) + ((100vw - #{$container-width}) / 2));
    background-color: $colorWhite;
    box-shadow: 0 0 24px rgba($colorBlack, 0.24);
    padding: 23px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1;

    @include max(1120px) {
      left: 20px;
    }

    @include sm {
      min-height: 252px;
      width: calc((100% - 40px) * 0.83);
      bottom: -50%;
      padding: 29px 20px;
    }

    @include max(530px) {
      width: calc(100% - 40px);
      padding: 20px;
      bottom: -70%;
    }

    @include xs {
      min-height: 0;
      width: 100%;
      left: 0;
      top: 100%;
      bottom: auto;
    }
  }

  .slide-title {
    font-size: 3.2rem;
    font-weight: bold;
    font-family: $fontDroidSans;
    color: $colorBlue1;
    width: 100%;

    @include sm {
      font-size: 4rem;
      margin-bottom: 20px;
    }

    @include xs {
      font-size: 3.2rem;
    }

    &.slide-in {
      @extend %animated;
      @extend %slide-in;
    }

    &.fade-out {
      @extend %animated;
      @extend %fade-out;
    }
  }

  .slide-pager {
    margin-top: auto;
    display: flex;
    align-items: center;
  }

  .slide-pages {
    font-size: 5rem;
    color: $colorDarkGray1;
    font-family: $fontDroidSans;

    @include sm {
      font-size: 6rem;
    }

    @include max(380px) {
      display: none;
    }
  }

  .slide-controls {
    list-style: none;
    margin: 0 0 0 auto;
    font-size: 0;
    padding: 0;

    @include max(380px) {
      margin: 0 auto;
    }

    li {
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      border: 2px solid $colorBlue;
      width: 69px;
      height: 69px;
      margin-right: 14px;
      cursor: pointer;
      @include transition;

      @include sm {
        width: 80px;
        height: 80px;
      }

      @include xs {
        width: 69px;
        height: 69px;
      }

      &:hover {
        background-color: $colorBlue;
      }

      &:last-child {
        margin-right: 0;
      }

      &.prev {
        background-image: url(../../images/icon-prev-slide.png);

        &:hover {
          background-image: url(../../images/icon-prev-slide-white.png);
        }
      }

      &.pause {
        background-image: url(../../images/icon-pause-slide.png);

        &:hover {
          background-image: url(../../images/icon-pause-slide-white.png);
        }

        &.paused {
          background-image: url(../../images/icon-pause-slide-white.png);
          background-color: $colorBlue;
        }
      }

      &.next {
        background-image: url(../../images/icon-next-slide.png);

        &:hover {
          background-image: url(../../images/icon-next-slide-white.png);
        }
      }
    }
  }

  .slick-list {
    width: 820px;
    max-width: 100%;
    overflow: visible;
    margin: 0;
    margin-left: calc((#{$container-width} / 12) + ((100vw - #{$container-width}) / 2) + 20px);
    position: relative;
    font-size: 0;

    @include max(1120px) {
      margin-left: 40px;
    }

    @include max(900px) {
      margin-left: 20px;
    }

    @include max(860px) {
      margin-left: 10px;
    }

    @include max(820px) {
      margin: 0;
    }

    &:before,
    &:after {
      content: "";
      background-color: rgba($colorDarkBlue4, 0.8);
      width: 300%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;

      @include max(820px) {
        display: none;
      }
    }

    &:before {
      left: -300%;
    }

    &:after {
      right: -300%;
    }
  }

  .slick-slider {
    .slick-slide {
      width: 820px;
      max-width: 100%;
      position: relative;
      outline: 0;

      @include max(820px) {
        width: 100vw;
      }
    }

    .slick-autoplay-toggle-button {
      display: none;
    }
  }

  #block-ladapt-block-vous-etes-home {
    background-color: $colorLightGray9;
    padding: 200px 0 30px;

    @include sm {
      padding-top: 250px;
    }

    @include xs {
      padding-top: 300px;
    }

    .content {
      @extend %container;
    }

    h2 {
      margin: 0 0 88px;
      font-weight: normal;
      color: $colorViolet;
      text-align: center;
      font-size: 6rem;
      font-family: $fontDroidSans;

      span {
        font-weight: normal;
        font-family: $fontBauer;
        font-size: 8rem;

        @include xs {
          font-size: 7rem;
        }
      }

      @include xs {
        font-size: 6rem;
      }
    }

    .entrees {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        display: inline-flex;
        font-size: 2.6rem;
        color: $colorBlue1;
        width: 25%;
        height: 118px;
        border: 1px solid $colorLightGray9;

        @include md {
          font-size: 2.3rem;
        }

        @include max(1000px) {
          font-size: 2rem;
        }

        @include max(960px) {
          width: 50%;
          min-height: 125px;
          font-size: 3.2rem;
        }

        @include max(700px) {
          font-size: 2.3rem;
        }

        @include max(600px) {
          width: 100%;
          font-size: 2.3rem;
          min-height: 118px;
        }

        a {
          display: inline-flex;
          align-items: center;
          background-color: $colorWhite;
          background-repeat: no-repeat;
          letter-spacing: letter-spacing(100);
          padding-left: 125px;
          padding-right: 5px;
          width: 100%;
          height: 100%;
          @include transition;

          @include max(1140px) {
            padding-left: 100px;
          }

          @include max(960px) {
            padding-left: 125px;
          }

          &:hover {
            background-color: $colorDarkGray;
            color: $colorWhite;
          }
        }

        &.entree--first {
          align-items: center;
          justify-content: center;
          padding-left: 0;
          margin: -20px 0 0 -20px;
          background-color: $colorYellow;
          width: calc(25% + 20px);
          height: 138px;
          font-size: 5rem;
          font-family: $fontDroidSans;
          font-weight: bold;
          color: $colorBlack;

          @include max(960px) {
            width: calc(50% + 20px);
            min-height: 145px;
            padding-right: 28px;
          }

          @include max(600px) {
            width: calc(100% + 40px);
            padding-right: 0;
            margin-right: -20px;
          }
        }

        &.entree--adherent a {
          background-image: url(../../images/icon-vous-adherent.png);
          background-size: 70px;
          background-position: left 33px center;

          @include max(1140px) {
            background-size: 57px;
            background-position: left 26px center;
          }

          @include max(960px) {
            background-image: url(../../images/icon-vous-adherent@2x.png);
            background-size: 71px;
            background-position: left 33px center;
          }

          &:hover {
            background-image: url(../../images/icon-vous-adherent-white.png);

            @include max(960px) {
              background-image: url(../../images/icon-vous-adherent-white@2x.png);
            }
          }
        }

        &.entree--benevole a {
          background-image: url(../../images/icon-vous-benevole.png);
          background-size: 54px;
          background-position: left 41px center;
          letter-spacing: normal;

          @include max(1140px) {
            background-size: 43px;
            background-position: left 33px center;
          }

          @include max(960px) {
            background-image: url(../../images/icon-vous-benevole@2x.png);
            background-size: 54px;
            background-position: left 41px center;
          }

          &:hover {
            background-image: url(../../images/icon-vous-benevole-white.png);

            @include max(960px) {
              background-image: url(../../images/icon-vous-benevole-white@2x.png);
            }
          }
        }

        &.entree--handicap a {
          background-image: url(../../images/icon-vous-handicap.png);
          background-size: 72px;
          background-position: left 27px center;
          letter-spacing: normal;

          @include max(1140px) {
            background-size: 58px;
            background-position: left 22px center;
          }

          @include max(960px) {
            background-image: url(../../images/icon-vous-handicap@2x.png);
            background-size: 72px;
            background-position: left 27px center;
          }

          &:hover {
            background-image: url(../../images/icon-vous-handicap-white.png);

            @include max(960px) {
              background-image: url(../../images/icon-vous-handicap-white@2x.png);
            }
          }
        }

        &.entree--parent a {
          background-image: url(../../images/icon-vous-parent.png);
          background-size: 91px;
          background-position: left 10px center;

          @include max(1140px) {
            background-size: 74px;
            background-position: left 8px center;
          }

          @include max(960px) {
            background-image: url(../../images/icon-vous-parent@2x.png);
            background-size: 92px;
            background-position: left 10px center;
          }

          &:hover {
            background-image: url(../../images/icon-vous-parent-white.png);

            @include max(960px) {
              background-image: url(../../images/icon-vous-parent-white@2x.png);
            }
          }
        }

        &.entree--entreprise a {
          background-image: url(../../images/icon-vous-entreprise.png);
          background-size: 87px;
          background-position: left 27px center;

          @include max(1140px) {
            background-size: 66px;
            background-position: left 22px center;
          }

          @include max(960px) {
            background-image: url(../../images/icon-vous-entreprise@2x.png);
            background-size: 83px;
            background-position: left 27px center;
          }

          &:hover {
            background-image: url(../../images/icon-vous-entreprise-white.png);

            @include max(960px) {
              background-image: url(../../images/icon-vous-entreprise-white@2x.png);
            }
          }
        }

        &.entree--recherche-emploi a {
          background-image: url(../../images/icon-vous-recherche-emploi.png);
          background-size: 68px;
          background-position: left 34px center;
          letter-spacing: normal;

          @include max(1140px) {
            background-size: 54px;
            background-position: left 27px center;
          }

          @include max(960px) {
            background-image: url(../../images/icon-vous-recherche-emploi@2x.png);
            background-size: 69px;
            background-position: left 34px center;
          }

          &:hover {
            background-image: url(../../images/icon-vous-recherche-emploi-white.png);

            @include max(960px) {
              background-image: url(../../images/icon-vous-recherche-emploi-white@2x.png);
            }
          }
        }

        &.entree--professionnel a {
          background-image: url(../../images/icon-vous-professionnel.png);
          background-size: 67px;
          background-position: left 32px center;
          font-size: 2.3rem;
          letter-spacing: normal;

          @include max(1140px) {
            background-size: 54px;
            font-size: 2rem;
            background-position: left 26px center;
          }

          @include max(960px) {
            background-image: url(../../images/icon-vous-professionnel@2x.png);
            background-size: 68px;
            background-position: left 32px center;
            font-size: 2.6rem;
          }

          @include max(700px) {
            font-size: inherit;
          }

          &:hover {
            background-image: url(../../images/icon-vous-professionnel-white.png);

            @include max(960px) {
              background-image: url(../../images/icon-vous-professionnel-white@2x.png);
            }
          }
        }
      }
    }
  }

  #block-ladapt-block-pres-chez-vous-home {
    background-color: $colorDarkBlue5;
    padding: 69px 0 66px;
    position: relative;
    overflow: hidden;
    z-index: 0;

    @include sm {
      background-color: $colorBlue;
      padding: 39px 0 80px;
    }

    &:before {
      content: "";
      background-color: $colorBlue;
      position: absolute;
      width: 300%;
      right: 50%;
      top: -100%;
      height: 300%;
      transform: skew(-7deg);
      z-index: 0;

      @include sm {
        display: none;
      }
    }

    .content {
      @extend %container;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;

      @include sm {
        display: block;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 36px;
        width: 50%;

        @include sm {
          width: 100%;
          margin-top: 47px;
        }

        > p {
          color: $colorYellow;
          font-size: 3.5rem;
          margin: 0;

          @include sm {
            font-size: 4.5rem;
            margin-bottom: 56px;
          }

          @include xs {
            font-size: 3.5rem;
          }
        }

        form {
          margin-top: auto;

          @include sm {
            margin-top: 0;
          }

          .form-item {
            display: inline;
          }
        }

        &:nth-child(2) {
          padding-right: 20px;

          @include sm {
            padding-right: 0;
          }

          form {
            margin-top: 55px;

            @include sm {
              margin-top: 0;
            }
          }
        }

        &:nth-child(3) {
          padding-left: 20px;

          @include sm {
            padding-left: 0;
            padding-top: 64px;
            margin-top: 102px;
            @include fullWidthBackground($colorDarkBlue5);
            overflow: visible;

            &:before {
              height: 1000%;
              top: 0;
            }
          }

          @include xs {
            margin-top: 64px;
          }

          label {
            display: none;
          }
        }
      }
    }

    h2 {
      flex: 0 0 100%;
      display: inline;
      margin: 0;
      font-size: 5rem;
      font-weight: bold;
      font-family: $fontDroidSans;
      color: $colorBlue1;

      @include sm {
        font-size: 6rem;
        background: $colorWhite;
        height: auto;
      }

      @include xs {
        font-size: 5rem;
      }

      &:first-line {
        background: $colorWhite;
      }
    }

    a {
      flex: 0 0 100%;
    }

    label {
      font-size: 2.5rem;
      font-weight: bold;
      color: $colorWhite;
      font-family: $fontDroidSans;
      margin-bottom: 20px;

      @include sm {
        font-size: 3.5rem;
      }

      @include xs {
        font-size: 2.5rem;
      }
    }

    input,
    select {
      width: calc(100% - 185px);
      height: 56px;
      border: 0;
      font-size: 1.8rem;
      font-weight: 500;
      margin-right: 13px;

      @include max(1000px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      @include sm {
        width: calc(100% - 255px);
        margin-right: 20px;
        margin-bottom: 0;
        font-size: 2.8rem;
        height: 62px;
      }

      @include xs {
        width: 100%;
        margin-right: 0;
        font-size: 1.8rem;
      }
    }

    input {
      border-bottom: 3px solid $colorWhite;
      background-color: transparent;
      color: $colorWhite;
      padding: 0;

      &::-webkit-input-placeholder {
        color: $colorWhite;
        font-style: italic;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: $colorWhite;
        font-style: italic;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $colorWhite;
        font-style: italic;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: $colorWhite;
        font-style: italic;
        opacity: 1;
      }
    }

    select {
      background-color: $colorWhite;
    }

    .form-submit {
      @include max(1000px) {
        width: 100%;
      }

      @include sm {
        width: 228px;
        height: 62px;
      }

      @include xs {
        width: 100%;
      }
    }

    .tout-savoir {
      @extend %white-radius-button, %tout-savoir-button;

      @include sm {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  #block-views-actualites-block {
    background-color: $colorLightGray6;
    padding: 27px 0 58px;

    .content {
      @extend %container;
    }

    .view-header {
      text-align: center;

      h2 {
        display: inline-block;
        background-color: $colorGray3;
        color: $colorBlue1;
        font-size: 4.5rem;
        font-weight: bold;
        font-family: $fontDroidSans;
        letter-spacing: letter-spacing(20);
        padding: 10px 35px;
        line-height: 1;
      }
    }

    .view-content {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      @include sm {
        margin: 0;
      }

      .views-row {
        width: calc(33.33% - 20px);

        @include sm {
          width: 100%;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          border-bottom: 13px solid $colorYellow;

          @include sm {
            border-bottom-width: 23px;
          }

          @include xs {
            border-bottom-width: 13px;
          }

          .image {
            display: none;
          }

          h3 {
            margin: 70px 0 35px;

            @include sm {
              margin-bottom: 120px;
            }
          }
        }

        &:nth-child(5) {
          border-color: $colorLightBlue;
        }

        &:nth-child(6) {
          border-color: $colorBlue6;
        }
      }
    }

    .view-footer {
      text-align: center;

      a {
        @extend %white-radius-button, %tout-savoir-button;
        margin-top: 44px;
        box-shadow: 0 0 27px rgba($colorBlack, 0.14);

        &:hover,
        &:active {
          background-color: $colorBlue;
          color: $colorWhite;
          box-shadow: 0 0 9px rgba($colorBlack, 0.14);
        }
      }
    }
  }

  .view-actualites,
  .view-territoires {
    .views-row {
      margin: 25px 10px;
      background-color: $colorWhite;
      position: relative;
      box-shadow: 0 0 18px rgba($colorBlack, 0.31);
      display: flex;
      flex-wrap: wrap;
      @include transition;

      &:hover {
        box-shadow: 0 0 9px rgba($colorBlack, 0.31);
      }

      @include sm {
        width: 100%;
        margin: 30px 0;
      }

      @include xs {
        margin: 20px 0;
      }

      .field-actualite-type {
        position: absolute;
        top: -9px;
        left: -9px;
        background-color: $colorBlue6;
        color: $colorWhite;
        padding: 9px;
        font-size: 2rem;
        line-height: 1;
        letter-spacing: letter-spacing(80);
        max-width: 80%;

        @include sm {
          font-size: 3rem;
        }

        @include xs {
          font-size: 2rem;
        }
      }

      .image {
        font-size: 0;

        @include sm {
          flex: 1 1;
        }

        @include xs {
          flex: 0 0 100%;

          img {
            width: 100%;
          }
        }
      }

      h3,
      .views-field-title {
        margin: 25px 0 30px;
        padding: 0 20px;
        color: $colorBlue1;
        font-size: 2.7rem;
        font-weight: bold;

        @include sm {
          flex: 1 1;
          font-size: 3.2rem;
          margin-bottom: 120px;
        }

        @include xs {
          font-size: 2.7rem;
        }
      }

      .read-more,
      .views-field-view-node {
        margin-top: auto;
        padding: 0 20px 30px;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @include sm {
          position: absolute;
          bottom: 0;
        }

        a {
          @extend %blue-border-button;
          border-color: $colorDarkGray;
          color: $colorDarkGray;

          &:hover {
            border-color: $colorBlue;
          }

          @include sm {
            font-size: 2.8rem;
            height: 56px;
          }

          @include xs {
            font-size: 1.8rem;
            height: 44px;
          }
        }
      }
    }
  }

  .region.region-main-suffix,
  .region.region-main-suffix {
    @extend %container;
    padding: 30px 10px 81px;
    display: flex;
    position: relative;
    z-index: 0;

    @include max(1100px) {
      flex-wrap: wrap;
    }

    > div {
      width: 33.33%;
      padding: 0 10px;

      @include max(1100px) {
        width: 50%;
      }

      @include sm {
        width: 100%;
      }
    }
  }

  #block-ladapt-block-timeline-facebook,
  #block-ladapt-block-timeline-twitter {
    @include max(1100px) {
      margin-bottom: 52px;
    }

    @include sm {
      margin-bottom: 130px;
    }

    @include xs {
      margin-bottom: 52px;
    }

    h2 {
      font-size: 3.2rem;
      letter-spacing: letter-spacing(20);
      color: $colorWhite;
      margin: 0;
      padding-left: 105px;
      position: relative;
      line-height: 66px;

      @include sm {
        font-size: 5.5rem;
        line-height: 120px;
        padding-left: 204px;
      }

      @include xs {
        font-size: 3.2rem;
        line-height: 66px;
        padding-left: 105px;
      }

      &:before {
        content: "";
        width: 85px;
        height: 85px;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;

        @include sm {
          width: 154px;
          height: 154px;
        }

        @include xs {
          width: 85px;
          height: 85px;
        }
      }

      &:after {
        content: "";
        width: 88px;
        height: 88px;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        background-color: rgba($colorBlack, 0.1);
        z-index: 0;

        @include sm {
          width: 159px;
          height: 159px;
        }

        @include xs {
          width: 88px;
          height: 88px;
        }
      }
    }

    .content {
      border: 3px solid $colorLightGray;
      border-width: 0 3px 3px 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-bottom: 50px;
    }

    ul {
      height: 354px;
      overflow-y: auto;
      padding: 33px;
      list-style: none;
      margin: 0;
      max-width: 100%;
      overflow-x: hidden;

      @include sm {
        height: auto;
        padding: 78px 14px;
      }

      @include xs {
        padding: 33px 14px;
      }
    }

    li {
      padding: 12px 0;
      border-bottom: 1px solid $colorLightGray10;
      color: $colorBlack;
      font-size: 1.5rem;
      letter-spacing: letter-spacing(20);

      &:last-child {
        border-bottom: 0;
      }

      @include sm {
        display: none;
        border-bottom: 0;
        padding: 0;
        font-size: 2.7rem;

        &:first-of-type {
          display: block;
        }
      }

      @include xs {
        font-size: 1.5rem;
      }

      .date {
        display: inline-block;
        background-color: $colorLightGray10;
        padding: 4px 10px;
        line-height: 1;
        margin-bottom: 12px;

        @include sm {
          margin-bottom: 20px;
          padding: 8px 10px;
        }
      }

      a {
        color: $colorBlue;
      }
    }

    .link,
    .like {
      width: calc(100% - 70px);
      position: absolute;
      bottom: -27px;
      left: 50%;
      transform: translateX(-50%);

      @include sm {
        bottom: -48px;
      }

      @include max(600px) {
        width: 100%;
      }

      @include xs {
        bottom: -27px;
      }

      a {
        color: $colorWhite;
        font-size: 2.5rem;
        font-weight: bold;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 17px rgba($colorBlack, 0.4);
        border-radius: 27px;
        @include transition;

        @include sm {
          font-size: 4.5rem;
          height: 96px;
          border-radius: 48px;
        }

        @include xs {
          font-size: 2.5rem;
          height: 54px;
          border-radius: 27px;
        }

        &:before {
          content: "";
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 20px;
          display: block;
        }

        &:hover {
          box-shadow: 0 0 5px rgba($colorBlack, 0.4);
        }
      }
    }
  }

  #block-ladapt-block-timeline-facebook {
    h2 {
      background-color: $colorFacebook;

      &:before {
        background-color: $colorFacebook;
        background-image: url(../../images/icon-facebook.png);

        @include sm {
          background-image: url(../../images/icon-facebook@2x.png);
          background-size: 56px;
        }

        @include xs {
          background-size: 31px;
        }
      }
    }

    .like a {
      background-color: $colorFacebook;
      letter-spacing: letter-spacing(100);

      &:before {
        background-image: url(../../images/icon-like.png);
        width: 29px;
        height: 30px;

        @include sm {
          background-image: url(../../images/icon-like@2x.png);
          background-size: 52px;
          width: 52px;
          height: 54px;
        }

        @include xs {
          background-size: 29px;
          width: 29px;
          height: 30px;
        }
      }

      &:hover {
        background-color: $colorFacebookHover;
      }
    }
  }

  #block-ladapt-block-timeline-twitter {
    h2 {
      background-color: $colorTwitter;

      &:before {
        background-color: $colorTwitter;
        background-image: url(../../images/icon-twitter.png);

        @include sm {
          background-image: url(../../images/icon-twitter@2x.png);
          background-size: 99px;
        }

        @include xs {
          background-size: 59px;
        }
      }
    }

    .user {
      @extend %clearfix;

      .user-avatar {
        float: left;
        margin-right: 10px;
        border-radius: 3px;
      }

      .user-name {
        font-weight: bold;
        margin-bottom: 3px;
        font-size: 1.4rem;
        padding-top: 5px;

        @include sm {
          margin-bottom: 0px;
          font-size: 2rem;
          padding-top: 3px;
        }

        @include xs {
          margin-bottom: 3px;
          font-size: 1.4rem;
          padding-top: 5px;
        }
      }

      .user-screen-name {
        font-size: 1.3rem;
        color: $colorGray;
      }
    }

    .message {
      margin-top: 15px;
    }

    .link a {
      background-color: $colorTwitter;
      letter-spacing: letter-spacing(20);

      &:before {
        background-image: url(../../images/icon-twitter.png);
        background-size: 34px;
        width: 34px;
        height: 28px;

        @include sm {
          background-image: url(../../images/icon-twitter@2x.png);
          background-size: 58px;
          width: 58px;
          height: 48px;
        }

        @include xs {
          background-size: 34px;
          width: 34px;
          height: 28px;
        }
      }

      &:hover {
        background-color: $colorTwitterHover;
      }
    }
  }

  #block-ladapt-block-social-links {
    height: 473px;

    @include max(1100px) {
      width: 100%;
    }

    @include sm {
      height: auto;
    }

    .content {
      height: 100%;

      @include sm {
        height: auto;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(50% - 11px);
      background-color: $colorBlue6;
      color: $colorWhite;
      font-size: 2.4rem;
      letter-spacing: letter-spacing(20);
      padding: 0 53px;
      @include transition;

      &:hover {
        background-color: $colorDarkBlue6;
      }

      &:first-of-type {
        margin-bottom: 22px;
      }

      &:before {
        content: "";
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 30px;
        flex: 0 0 auto;
      }

      @include sm {
        height: 200px;
        font-size: 3.3rem;
        padding: 0 47px;
        justify-content: flex-start;
      }

      @include xs {
        font-size: 2.4rem;
        padding: 0 30px;
      }
    }

    .youtube {
      background-image: url(../../images/bg-youtube.png);
      background-position: right -18px bottom -79px;
      background-repeat: no-repeat;

      &:before {
        background-image: url(../../images/icon-youtube.png);
        width: 80px;
        height: 95px;

        @include sm {
          background-image: url(../../images/icon-youtube@2x.png);
          background-size: 107px;
          width: 107px;
          height: 127px;
        }

        @include xs {
          background-size: 80px;
          width: 80px;
          height: 95px;
        }
      }

      &:hover {
        background-image: url(../../images/bg-youtube-hover.png);
      }
    }

    .linkedin {
      background-image: url(../../images/bg-linkedin.png);
      background-position: right -38px bottom -63px;
      background-repeat: no-repeat;

      &:before {
        background-image: url(../../images/icon-linkedin.png);
        width: 73px;
        height: 72px;

        @include sm {
          background-image: url(../../images/icon-linkedin@2x.png);
          background-size: 90px;
          width: 90px;
          height: 88px;
        }

        @include xs {
          background-size: 73px;
          width: 73px;
          height: 72px;
        }
      }

      &:hover {
        background-image: url(../../images/bg-linkedin-hover.png);
      }
    }
  }

  #block-ladapt-block-iframe-couleurs-handicap {

    @include min(768px) {

      width: 100%;

      .content {
        display: flex;
        flex-direction: row;
      }
      .content > .image-link {
        width: 33%;
        margin-left: 100px;
      }
      .content > div {
        width: 400px;
        height: 282px;
        cursor: pointer;
      }
      object, .iframeMirror {
        top: 40%;
        transform: translateY(-50%);
        position: absolute;
      }
      object {
        z-index: 1;
        text-align: initial;
      }
      .iframeMirror {
        z-index: 5;
        width: 400px;
        height: 282px;
      }

    }
  }
}
