.node-type-actualite {
  #page-title {
    display: none;
  }

  .view-meme-sujet-article {
    margin-top: 73px;
    margin-bottom: -100px;
    padding: 140px 0;
    @include fullWidthBackground($colorBlue);

    @include sm {
      padding: 79px 0;
      margin-bottom: -164px;
    }

    @include xs {
      margin-bottom: -100px;
    }
  }
}

.group-header {
  display: flex;
  align-items: center;
  color: $colorWhite;
  min-height: 350px;
  @include fullWidthBackground($colorBlue5);

  @include sm {
    flex-wrap: wrap;
    padding: 56px 0 29px;
  }

  @include xs {
    padding: 29px 0;
  }

  .required-fields {
    padding: 0 20px 0 8.33%;
    width: 58.33%;

    @include md {
      padding: 10px 20px 10px 0;
    }

    @include sm {
      padding: 0;
      width: 100%;
    }

    .field-actualite-type {
      font-size: 2rem;
      letter-spacing: letter-spacing(80);

      @include sm {
        font-size: 3rem;
      }

      @include xs {
        font-size: 2rem;
      }
    }

    h1 {
      font-size: 5rem;
      font-weight: bold;
      margin: 0;
      line-height: 1.1;

      @include sm {
        font-size: 6rem;
      }

      @include xs {
        font-size: 5rem;
      }

      &:before {
        content: "";
        display: block;
        margin: 17px 0 23px;
        width: 83.33%;
        height: 2px;
        background-color: $colorDarkBlue3;

        @include md {
          margin: 7px 0 13px;
        }

        @include sm {
          width: 100%;
          margin: 22px 0;
        }

        @include xs {
          margin: 10px 0;
        }
      }
    }

    .wrapper-content-thematiques {
      a {
        color: $colorWhite;
      }
    }

    .wrapper-date-publi {
      font-family: $fontDroidSans;
      font-size: 1.7rem;
      margin-top: 30px;

      @include md {
        margin-top: 15px;
      }

      @include sm {
        font-size: 2.7rem;
        margin-top: 45px;
      }

      @include xs {
        font-size: 1.7rem;
        margin-top: 20px;
      }

      .date-created {
        display: inline-block;
        padding-left: 4px;
      }
    }
  }

  picture,
  .field-image-principale {
    width: 41.67%;
    font-size: 0;

    @include sm {
      width: 100%;
      margin-top: 30px;
    }

    img {
      min-height: 350px;
      width: auto;
      object-fit: cover;

      @include sm {
        height: auto;
        width: 100%;
      }
    }
  }
}

.field-chapo {
  font-weight: bold;
  font-size: 1.8rem;
  color: $colorBlack;
  margin: 73px 0 43px;
  padding: 0 8.33%;

  @include sm {
    padding: 0;
    font-size: 2.8rem;
  }

  @include xs {
    font-size: 1.8rem;
    margin: 30px 0;
  }
}

.group-content {
  padding-top: 52px;
  @include fullWidthBackground($colorLightGray8);
  @extend %clearfix;

  @include sm {
    padding-top: 0;
  }

  &:before {
    content: "";
    height: 20px;

    @include sm {
      display: none;
    }
  }

  .group-left {
    float: left;
    width: 25%;
    padding-right: 20px;
    position: relative;

    @include sm {
      width: 100%;
      padding: 0;
    }
  }

  .group-left + .group-right {
    float: left;
    width: 75%;

    @include sm {
      width: 100%;
    }
  }
}

.list-group-wrap-keyword {
  list-style: none;
  margin: 0;
  padding: 28px 0 0;
  font-size: 0;
  border-top: 1px solid $colorLightGray5;

  li {
    display: none;
    font-size: 1.8rem;
    font-weight: 500;
    margin-right: 14px;

    @include sm {
      font-size: 2.8rem;
    }

    @include xs {
      font-size: 1.8rem;
    }

    &.field-mots-cles {
      display: inline-block;

      a {
       color: $colorBlue1;

       &:before {
         content: "#";
       }

       &:hover {
         text-decoration: underline;
       }
      }
    }
  }
}

.toc-wrapper {
  @include sm {
    margin: 0 -20px;
  }
}

.toc {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: $colorWhite;
  box-shadow: 0 0 7px rgba($colorBlack, 0.15);

  @include sm {
    box-shadow: none;
  }

  li {
    @include sm {
      display: none;
    }

    &.sommaire {
      display: none;

      @include sm {
        display: block;
      }

      a {
        display: flex;
        align-items: center;
        color: $colorBlue1;
        background-color: $colorLightGray8;
        font-size: 4.2rem;
        padding: 10px 40px;
        border: none;

        &:after {
          content: "";
          background-image: url(../../images/icon-plus@2x.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 32px;
          display: inline-block;
          width: 49px;
          height: 49px;
          background-color: $colorWhite;
          border: 6px solid $colorBlue1;
          border-radius: 50%;
          margin-left: auto;
        }

        @include xs {
          font-size: 3.2rem;
        }
      }
    }
  }

  &.open {
      box-shadow: 0 11px 24px rgba($colorBlack, 0.59);

    li {
      display: block;

      &.sommaire {
        a {
          &:after {
            background-image: url(../../images/icon-minus@2x.png);
          }
        }
      }
    }
  }

  a {
    display: inline-block;
    width: 100%;
    padding: 15px 10px 15px 20px;
    text-align: right;
    font-family: $fontDroidSans;
    font-size: 1.8rem;
    font-weight: bold;
    color: $colorDarkGray1;
    border-right: 10px solid transparent;
    @include transition;

    @include sm {
      border-right: 20px solid transparent;
      font-size: 2.8rem;
      padding: 20px;
    }

    @include xs {
      font-size: 1.8rem;
      border-right: 10px solid transparent;
      padding: 15px 20px;
    }

    &:hover {
      background-color: $colorLightGray4;
    }

    &.current {
      color: $colorBlue;
      border-color: $colorBlue;
    }
  }
}

.toc.stick {
    position: fixed;
    top: 0;
    margin-top: 106px;
    z-index: 2;

    @include sm {
      margin-top: 0;
    }
}
