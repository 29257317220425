.ui-dialog.search {
  left: 0 !important;
  border: 0;
  border-radius: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 132px !important;
  width: 100% !important;
  z-index: 2;
  position: fixed;

  @include md {
    z-index: 4;
  }

  @include sm {
    height: auto !important;
    z-index: 1;
    padding: 58px 0;
    background-color: $colorWhite;
    animation-duration: 0.4s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-name: search-mobile-slide-down;

    &.closed {
      animation-duration: 0.4s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-name: search-mobile-slide-up;
    }
  }

  @include xs {
    padding: 20px 0;
  }

  .ui-dialog-titlebar {
    @extend %container;
    border: 0;
    background: $colorWhite;
    color: $colorBlue;
    font-size: 2.2rem;
    font-family: $fontRoboto;
    letter-spacing: letter-spacing(60);
    padding: 0 20px 0 223px;
    margin: 0 auto 5px;
    text-transform: uppercase;

    @include md {
      padding: 0 20px;
    }

    @include sm {
      font-size: 3.2rem;
      font-weight: bold;
      margin: 0 auto 23px;
    }

    @include xs {
      font-size: 2.2rem;
    }

    .ui-dialog-title {
      margin: 0;
      white-space: normal;
      width: 100%;
      overflow: visible;
      text-overflow: inherit;
    }
  }

  .ui-dialog-titlebar-close {
    border: 0;
    background: none;
    right: 0;
    top: 0;
    width: auto;
    margin: 29px 20px 0 0;
    padding: 0;
    height: auto;
    z-index: 1;

    @include md {
      margin-top: 0;
    }

    @include sm {
      display: none;
    }

    .ui-button-icon-primary {
      left: 0;
      top: 0;
      margin: 0;
      position: static;
      width: 53px;
      height: 53px;
      background-image: url(../../images/icon-close.png);
      background-position: 0 0;
    }

    &:hover {
      .ui-button-icon-primary {
        background-position: -53px 0;
      }
    }

    .ui-button-text {
      display: none;
    }
  }
}

#search-dialog-container {
  @extend %container;
  background: $colorWhite;
  height: auto !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 0 20px 0 223px;
  border-radius: 0;

  @include md {
    padding: 0 20px;
  }

  @include sm {
    display: block;
  }

  @include xs {
    padding: 0;
  }

  .item-list {
    float: left;
    width: 30%;
    min-width: 340px;

    @include sm {
      width: 100%;
      float: none;
    }

    .ui-tabs-nav {
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;
      background: none;

      li {
        border: 0;
        outline: 0;
        border-radius: 0;
        background: $colorLightGray;
        padding: 0;
        margin: 0;
        text-align: left;
        float: none;
        display: flex;
        align-items: center;
        height: 28px;
        @include transition;

        @include sm {
          height: 70px;
        }

        @include xs {
          height: 50px;
        }

        a {
          display: block;
          padding: 0 15px;
          outline: 0;
          float: none;
          font-family: $fontRoboto;
          font-size: 1.7rem;
          font-weight: 500;
          color: $colorDarkGray;
          width: 100%;
          @include transition;

          &::first-letter {
            text-transform: uppercase;
          }

          @include sm {
            font-size: 2.7rem;
          }

          @include xs {
            font-size: 1.7rem;
          }
        }

        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          margin: -5px 0 0 0;
          display: block;
          height: 0;
          border-color: transparent transparent transparent $colorLightGray;
          border-style: solid;
          border-width: 5px;
          opacity: 0;
          @include transition;

          @include sm {
            display: none;
          }
        }

        &:hover,
        &.ui-state-active {
          background-color: $colorBlue;

          a {
            color: $colorWhite;
          }

          &:after {
            border-color: transparent transparent transparent $colorBlue;
            margin: -5px -10px 0 0;
            opacity: 1;
          }
        }
      }
    }
  }

  .form-item-search-block-form {
    vertical-align: bottom;
  }

  form {
    font-size: 0;
  }

  label {
    width: 100%;
    float: left;
    font-family: $fontDroidSans;
    font-weight: bold;
    font-size: 1.7rem;
    color: $colorBlue;
    margin-bottom: 0;

    @include sm {
      margin-bottom: 25px;
      font-size: 2.7rem;
    }

    @include sm {
      font-size: 1.7rem;
      margin-bottom: 10px;
    }
  }

  input,
  select {
    height: 50px;
    width: 370px;

    @include md {
      width: calc(60% - 20px);
    }

    @include sm {
      height: 70px;
      width: calc(66.66% - 20px);
    }

    @include xs {
      height: 50px;
      width: 100%;
    }
  }

  input {
    margin-right: 0;
    font-size: 1.8rem;
    font-weight: 500;
    color: $colorBlack;
    border-width: 0;
    border-bottom: 3px solid $colorDarkGray;

    @include sm {
      font-size: 2.8rem;
    }

    @include xs {
      font-size: 1.8rem;
    }

    &[type="submit"] {
      @extend %yellow-button;
      margin: 0 0 0 20px;
      letter-spacing: normal;
      min-width: 0;
      padding: 0;

      @include md {
        width: 40%;
      }

      @include sm {
        height: 70px;
        width: 33.34%;
      }

      @include xs {
        height: 50px;
        width: 100%;
        margin: 13px 0 0 0;
      }
    }

    &::-webkit-input-placeholder {
      color: $colorDarkGray;
      font-style: italic;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: $colorDarkGray;
      font-style: italic;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $colorDarkGray;
      font-style: italic;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: $colorDarkGray;
      font-style: italic;
      opacity: 1;
    }
  }
}

#search-full-text,
#search-etab-dpt,
#search-etab-service {
  padding: 0 20px;
  width: 70%;

  @include md {
    padding-right: 0;
  }

  @include sm {
    padding: 0;
    margin-top: 45px;
    width: 100%;
  }

  @include xs {
    margin-top: 25px;
    padding: 0 20px;
  }
}

body.sticky {
  .ui-dialog.search {
    @include sm {
      animation-duration: 0.4s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-name: search-mobile-slide-down-sticky;

      &.closed {
        animation-duration: 0.4s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-name: search-mobile-slide-up-sticky;
      }
    }
  }
}

@include min(1281px) {
  .recherche-opened.sticky {
    header {
      height: 0;
      background: transparent;

      #recherche,
      #logo,
      .faire-un-don {
        display: none;
      }
    }

    .ui-dialog.search {
      z-index: 1;
    }

    .hamburger {
      margin-top: 130px;
    }
  }
}

.form-item-retain-filters {
  display: none !important;
}

