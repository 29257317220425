////////////////////////
// Variables Partials
//
// All of your variables should go in this file.
////////////////////////

// Widths
$md-width:            1280px;
$sm-width:            768px;
$xs-width:            480px;

$container-width:     1280px;

// Fonts
$fontRoboto:          'Roboto', sans-serif;
$fontRobotoCondensed: 'Roboto Condensed', sans-serif;
$fontDroidSans:       'Droid Sans', sans-serif;
$fontMontserrat:      'Montserrat', sans-serif;
$fontBauer:           'Bauer Bodoni Std', sans-serif;
$fontGotham:          'Gotham Book', sans-serif;

// Colors
$colorRed:		        #D22100;
$colorDarkRed:        #B1170F;
$colorBlue:           #1B3F83;
$colorBlue1:          #1C3F84;
$colorBlue2:          #1A4182;
$colorBlue3:          #214897;
$colorBlue4:          #004C99;
$colorBlue5:          #194181;
$colorBlue6:          #004B99;
$colorBlue7:          #017BB6;
$colorBlue8:          #466790;
$colorDarkBlue:       #18336A;
$colorDarkBlue1:      #0D244C;
$colorDarkBlue2:      #091D3E;
$colorDarkBlue3:      #15336B;
$colorDarkBlue4:      #0C2C6B;
$colorDarkBlue5:      #163470;
$colorDarkBlue6:      #003D7C;
$colorDarkBlue7:      #132D63;
$colorLightBlue:      #65A7D9;
$colorLightBlue1:     #92C9F2;
$colorLightBlue2:     #36599F;
$colorOrange:         #FF584C;
$colorWhite:          #FFFFFF;
$colorGray:           #808080;
$colorGray1:          #A7A7A7;
$colorGray2:          #D3D3D3;
$colorGray3:          #D2D2D2;
$colorDarkGray:       #454343;
$colorDarkGray1:      #464444;
$colorDarkGray2:      #272727;
$colorDarkGray3:      #4B4B4B;
$colorLightGray:      #F6F6F6;
$colorLightGray1:     #CFCFCF;
$colorLightGray2:     #EDEDED;
$colorLightGray3:     #EAEAEA;
$colorLightGray4:     #E9E9E9;
$colorLightGray5:     #D8D8D8;
$colorLightGray6:     #E1E1E1;
$colorLightGray7:     #F7F7F7;
$colorLightGray8:     #F1F1F1;
$colorLightGray8:     #ECECEC;
$colorLightGray9:     #EEEEEE;
$colorLightGray10:    #D9D9D9;
$colorLightGray11:    #F4F4F4;
$colorLightGray12:    #F0F0F0;
$colorBlack:          #000000;
$colorBlack1:         #141313;
$colorViolet:         #9D297E;
$colorDarkViolet:     #832068;
$colorYellow:         #FDC736;
$colorDarkYellow:     #906A05;
$colorPink:           #EC1872;
$colorMint:           #ACE0ED;
$colorMint1:          #4B9B97;
$colorDarkMint:       #006462;
$colorFacebook:       #3B5997;
$colorFacebookHover:  #213768;
$colorTwitter:        #1DA1F1;
$colorTwitterHover:   #1387CF;
$colorSuccessMessage: #0FB14C;
$colorWarningMessage: #FF9900;

// Vendors
$fa-font-path:        "/sites/all/themes/ladapt/fonts/font-awesome";
$icon-twitter: "\f099";
$icon-facebook: "\f09a";
$icon-linkedin: "\f0e1";
$icon-mail: "\f0e0";
