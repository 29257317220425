.node-type-partenaire {
  #page-title {
    display: none;
  }

  .group-header {
    picture {
      img {
        min-height: auto;
      }
    }
  }
}
