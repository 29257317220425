////////////////////////
// Extendables Partials
//
// All of your extendable classes, ids, and placeholder extendables
//   should go in this file.
////////////////////////

%clearfix {
  &:after {
	  content: " ";
	  display: block;
	  clear: both;
  }
}

%container {
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

%yellow-button {
  background-color: $colorYellow;
  border: 0;
  color: $colorBlack;
  cursor: pointer;
  font-weight: bold;
  font-size: 2.2rem;
  height: 50px;
  width: 190px;
  @include transition;

  &:hover {
    background-color: $colorDarkYellow;
    color: $colorWhite;
  }
}

%blue-border-button {
  background-color: $colorWhite;
  border: 3px solid $colorBlue;
  height: 44px;
  color: $colorBlue;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  font-size: 1.7rem;
  font-weight: 500;
  @include transition;

  &:hover {
    background-color: $colorBlue;
    color: $colorWhite;
  }
}

%white-radius-button {
  align-items: center;
  background-color: $colorWhite;
  box-shadow: 0 0 10px rgba($colorBlack, 0.23);
  color: $colorBlue;
  display: inline-flex;
  font-family: $fontDroidSans;
  justify-content: center;
  letter-spacing: letter-spacing(20);
  @include transition;

  &:hover {
    background-color: $colorLightGray;
    box-shadow: 0 0 2px rgba($colorBlack, 0.23);
  }

  &:active {
    background-color: $colorLightGray1;
    box-shadow: 0 0 2px rgba($colorBlack, 0.23);
  }

  @include sm {
    letter-spacing: letter-spacing(-40);
  }
}

%tout-savoir-button {
  padding: 0 40px;
  height: 56px;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 28px;
  flex: 0 0 auto;
  margin: 89px auto 0;

  @include sm {
    font-size: 2.8rem;
    height: 82px;
    border-radius: 41px;
    position: relative;
    text-align: center;
    min-width: 0;
  }

  @include xs {
    font-size: 2rem;
    height: 56px;
    border-radius: 28px;
    margin-top: 60px;
    max-width: 100%;
  }
}
