////////////////////////
// Mixins Partials
//
// All of your mixins should go in this file.
////////////////////////

// Mixins for responsive design
@mixin max($maxwidth) {
  @media only screen and (max-width: $maxwidth) {
    @content;
  }
}

@mixin min($minwidth) {
  @media only screen and (min-width: $minwidth) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin overstrike($color: $colorWhite, $width: 90px) {
	content: "";
	display: block;
	width: $width;
	height: 1px;
	background-color: $color;
	margin: 10px 0;
}

@mixin placeholder-color($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
	/* FF 4-18 */
		color: $color;
	}
	&::-moz-placeholder {
		/* FF 19+ */
		color: $color;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: $color;
	}
}
@mixin placeholder-uppercase() {
	&::-webkit-input-placeholder {
		text-transform: uppercase;
	}
	&:-moz-placeholder {
	/* FF 4-18 */
		text-transform: uppercase;
	}
	&::-moz-placeholder {
		/* FF 19+ */
		text-transform: uppercase;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		text-transform: uppercase;
	}
}

@mixin arrow($direction, $color, $width, $height) {
  display: block;
  height: 0;
  width: 0;
  content: '';
	border-style: solid;

	@if $direction == 'top' {
		border-width: 0 $width/2 $height $width/2;
		border-color: transparent transparent $color transparent;
	}
	@else if $direction == 'bottom' {
		border-width: $height $width/2 0 $width/2;
		border-color: $color transparent transparent transparent;
	}
	@else if $direction == 'left' {
		border-width: $height/2 $width $height/2 0;
		border-color: transparent $color transparent transparent;
	}
	@else if $direction == 'right' {
		border-width: $height/2 0 $height/2 $width;
		border-color: transparent transparent transparent $color;
	}
	@else if $direction == 'top-left' {
		border-width: $height $width 0 0;
		border-color: $color transparent transparent transparent;
	}
	@else if $direction == 'top-right' {
		border-width: 0 $width $height 0;
		border-color: transparent $color transparent transparent;
	}
	@else if $direction == 'bottom-left' {
		border-width: $height 0 0 $width;
		border-color: transparent transparent transparent $color;
	}
	@else if $direction == 'bottom-right' {
		border-width: 0 0 $height $width;
		border-color: transparent transparent $color transparent;
	}
}

@mixin transition($element: all, $effect: ease, $duration: 0.2s) {
	transition: $element $effect $duration;
}

@mixin button($color, $width: 2px, $style: solid, $height: 50px) {
	border: $width $style $color;
	color: $color;
	font-family: $fontDroidSans;
	font-size: 2rem;
	font-weight: bold;
	height: $height;
	outline: 0;
	position: relative;
}

@mixin fullWidthBackground($color) {
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 300%;
    left: -100%;
    top: 0;
    background: $color;
    z-index: -1;
  }
}

@mixin social-icon-preset() {
  position: relative;
  left: -14px;
  font-family: FontAwesome;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin socials() {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;

  @media screen and (max-width: $md-width){
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
  }

  .social-text {
    font-size: 20px;
    font-weight: bold;
    color: $colorDarkGray1;
    margin-right: 38px;

    @media screen and (max-width: $sm-width){
      width: 100%;
      margin-bottom: 16px;
    }
  }

  a.social-network {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    font-size: 16px;
    border-radius: 4px;
    padding: 8px 16px 8px 32px;
    background-color: $colorDarkGray1;
    color: $colorWhite;
    font-weight: normal;
    transition: background-color 300ms ease;

    @media screen and (max-width: $sm-width){
      margin-right: 0;
      flex: 1 1 35%;
    }

    &:hover {
      @media screen and (min-width: $md-width) {
        background-color: $colorDarkGray2;
      }
    }

    &::before {
      @include social-icon-preset();
    }

    &.social-mail:before {
      content: $icon-mail;
    }
    &.social-linkedin:before {
      content: $icon-linkedin;
    }
    &.social-facebook:before {
      content: $icon-facebook;
    }
    &.social-twitter:before {
      content: $icon-twitter;
    }
    &.social-bluesky:before {
       content: $icon-bluesky;
     }
  }
}
