h2 {
  margin: 0 0 20px;
  font-family: $fontDroidSans;
  font-size: 3.8rem;
  font-weight: bold;
  color: $colorBlue1;

  @include sm {
    font-size: 4.8rem;
  }

  @include xs {
    font-size: 3.8rem;
  }
}

h3 {
  margin: 0 0 20px;
  font-family: $fontMontserrat;
  font-size: 2.6rem;
  color: $colorBlack;

  @include sm {
    font-size: 3.6rem;
  }

  @include xs {
    font-size: 2.6rem;
  }
}

article {
  a {
    color: $colorBlue1;
  }

  p {
    margin: 15px 0;
    color: $colorBlack;
    line-height: 1.6;
    font-size: 1.7rem;

    @include sm {
      font-size: 2.7rem;
    }

    @include xs {
      font-size: 1.7rem;
    }
  }

  ul:not(.toc):not(.list-group-wrap-keyword) {
    margin: 0 0 36px;
    padding: 0;
    list-style: none;
    color: $colorBlack;
    display: block;
    width: 100%;

    li {
      margin: 13px 0;
      font-size: 1.7rem;

      @include sm {
        font-size: 2.7rem;
      }

      @include xs {
        font-size: 1.7rem;
      }

      &::before {
        content: "• ";
        font-size: 2.2rem;
        color: $colorViolet;
        vertical-align: middle;
        display: inline;

        @include sm {
          font-size: 2.7rem;
        }

        @include xs {
          font-size: 2.2rem;
        }
      }

      & > p {
        display: inline;
      }
    }
  }

  ol {
    margin: 0 0 36px;
    padding: 0;
    list-style: none;
    color: $colorBlack;
    counter-reset: li;

    li {
      padding: 0;
      margin: 13px 0;
      font-size: 1.7rem;

      @include sm {
        font-size: 2.7rem;
      }

      @include xs {
        font-size: 1.7rem;
      }

      &:before {
        display: inline-block;
        padding-right: 5px;
        margin-right: 8px;
        counter-increment: li;
        content: counter(li);
        color: $colorViolet;
        font-weight: bold;
        border-right: 1px solid $colorBlack;
      }
    }
  }
}

.type-video {
  margin: 15px 0;
  width: 77.7777%;

  @include sm {
    width: 100%;
  }

  iframe {
    max-width: 100%;
  }

  .field-scald-tags {
    display: inline-block;

    a {
      &:before {
        content: "#";
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dnd-caption-wrapper {
    margin: 15px 0 40px;
    font-weight: bold;
  }
}

.context-grande_image {
  max-width: 77.7777%;

  @include sm {
    width: 100%;
  }
}

figure {
  margin: 0;

  figcaption {
    margin-top: 5px;
    font-weight: bold;

    .scald-credit {
      font-weight: normal;
    }
  }

  //img {
  //  width: 100%;
  //}
}

blockquote {
  margin: 90px 0;
  padding: 0;

  @include sm {
    margin: 130px 0;
  }

  @include xs {
    margin: 90px 0;
  }

  p {
    padding: 9px 14px;
    display: inline;
    background-color: $colorYellow;
    font-weight: bold;
    font-size: 2.6rem;
    font-family: $fontMontserrat;
    line-height: 42px;
    box-decoration-break: clone;

    @include sm {
      font-size: 3.6rem;
      line-height: 52px;
    }

    @include xs {
      font-size: 2.6rem;
      line-height: 42px;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      background-image: url(../../images/icon-quote-start.png);
      background-repeat: no-repeat;
      width: 33px;
      height: 29px;
      margin-top: -84px;

      @include sm {
        margin-top: -110px;
      }

      @include xs {
        margin-top: -84px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      background-image: url(../../images/icon-quote-end.png);
      background-repeat: no-repeat;
      width: 33px;
      height: 30px;
      margin-top: 55px;
      margin-left: -18px;

      @include sm {
        margin-top: 83px;
      }

      @include xs {
        margin-top: 55px;
      }
    }
  }
}

.rte-3-col {
  display: flex;
  margin: 15px -10px;

  @include sm {
    display: block;
    margin: 15px 0;
  }

  .cke_widget_wrapper {
    width: 100%;
  }

  .dnd-widget-wrapper {
    width: 33.3333%;
    padding: 0 10px;
  }

  figure {
    width: 100%;
  }

  @include sm {
    .dnd-widget-wrapper {
      width: 66.66%;
      margin: 36px auto;
      padding: 0;
    }
  }

  @include xs {
    .dnd-widget-wrapper {
      width: 100%;
    }
  }
}

.atom-align-right,
.atom-align-left {
  + p {
    @extend %clearfix;
  }

  figure {
    width: 100%;
  }

  @include sm {
    margin: 0 auto;
    width: 66.6666%;
    float: none;
  }

  @include xs {
    width: 100%;
  }
}

.atom-align-right {
  figure {
    padding-left: 20px;

    @include sm {
      padding: 0 0 20px;
    }
  }
}

.atom-align-left {
  figure {
    padding-right: 20px;

    @include sm {
      padding: 0 0 20px;
    }
  }
}

.cke_widget_wrapper {
  clear: both;
}

.note {
  margin: 17px 0 62px;
  padding: 16px 30px;
  background-color: $colorLightGray8 !important;
  clear: both;
}

.type-link,
.type-document,
.field-pdf-lie {
  display: flex;
  width: 55.5555%;
  height: 135px;
  margin: 15px 0;
  position: relative;
  background-color: $colorLightBlue2;
  font-weight: 500;
  @include transition;

  @include sm {
    width: 100%;
    height: 177px;
    margin: 28px 0;
  }

  @include xs {
    height: 135px;
    margin: 15px 0;
  }

  &:hover {
    background-color: $colorDarkBlue7;
  }

  .dnd-atom-rendered,
  .image {
      width: 100%;
      height: 100%;
  }

  a {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px 0 105px;
    color: $colorWhite;
    z-index: 1;

    @include sm {
      padding: 0 20px 0 124px;
    }

    @include xs {
      padding: 0 20px 0 105px;
    }
  }

  .atom-title {
    font-size: 1.8rem;
    font-weight: bold;

    @include sm {
      font-size: 2.8rem;
    }

    @include xs {
      font-size: 1.8rem;
    }

    &::after {
      content: "";
      display: block;
      width: 85px;
      height: 2px;
      background-color: $colorWhite;
      margin: 10px 0;

      @include sm {
        width: 104px;
        height: 4px;
      }

      @include xs {
        width: 85px;
        height: 2px;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 135px;
    width: 85px;
    background-color: $colorBlue;
    background-image: url(../../images/icon-pdf.png);
    background-repeat: no-repeat;
    background-position: right -22px bottom -18px;

    @include sm {
      height: 177px;
      width: 104px;
      background-image: url(../../images/icon-pdf@2x.png);
      background-position: right -37px bottom -25px;
    }

    @include xs {
      height: 135px;
      width: 85px;
      background-size: 85px;
      background-repeat: no-repeat;
      background-position: right -22px bottom -18px;
    }
  }
}
.type-link {
  &::before {
    background-image: url(../../images/icon-link.png);
    @include sm {
      background-image: url(../../images/icon-link@2x.png);
    }
  }
}

.context-taille_originale {
  margin: 60px 0 43px;

  figure {
    width: 100%;
  }

  img {
    width: 100%;
  }
}

.scald-atom-image {
  figcaption {
    display: none;
  }
}
