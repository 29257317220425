#lightbox2-overlay {
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba($colorBlack, 0.8);
  position: fixed;
}

aside#menu-principal {
  background-color: $colorWhite;
  width: 41.66%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  padding-top: 132px;
  padding-left: calc(((100% - #{$container-width}) / 2) + 20px);
  padding-right: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @include transition;

  @include md {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-left: 20px;
    width: 70%;
  }

  &.element-invisible {
    left: -100%;
    opacity: 0;
    clip: auto;
  }

  .region-header {
    display: flex;
    flex-direction: column;

    #block-system-main-menu {
      order: 2;
    }

    #block-ladapt-block-entrees-par-public {
      order: 1;
    }
  }

  @include sm {
    padding: 0 20px;
    margin-top: 132px;
    padding-bottom: 132px;
    width: 100%;

    &.element-invisible {
      visibility: hidden;
      left: 0;
      top: 0;
      margin-top: 0;
    }
  }
}

#navigation #block-ladapt-block-entrees-par-public {
  .intro {
    color: $colorBlue;
    font-size: 2.8rem;
    font-family: $fontDroidSans;
    font-weight: bold;
    letter-spacing: letter-spacing(50);
  }

  .entrees {
    margin: 26px 0 0;
    padding: 0;
    list-style: none;
    @include transition;
    height: 49px;
    position: relative;
    z-index: 1;

    &.open {
      li {
        pointer-events: auto;
        opacity: 1;

        &.entree--first {
          color: $colorWhite;
          border-color: $colorBlue;
          background-color: $colorBlue;

          &:after {
            background-position: -28px 0;
          }
        }
      }
    }

    li {
      display: flex;
      padding: 0;
      background-color: $colorWhite;
      border: 2px solid $colorGray1;
      border-width: 0 2px 2px;
      align-items: center;
      height: 60px;
      color: $colorBlue;
      font-size: 1.7rem;
      font-weight: 500;
      letter-spacing: letter-spacing(100);
      opacity: 0;
      box-shadow: 0 1px 7px rgba($colorBlack, 0.3);
      pointer-events: none;
      @include transition;

      a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        padding: 0 20px 0 0;

        &:before {
          content: "";
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat;
          width: 70px;
          height: 100%;
          flex-shrink: 0;
        }
      }

      &:hover {
        background-color: $colorLightGray2;
      }

      &.entree--adherent {
        a {
          &:before {
            background-image: url(../../images/icon-adherent.png);
            background-size: 38px;

            @include sm {
              background-image: url(../../images/icon-adherent@2x.png);
            }
          }
        }
      }

      &.entree--benevole {
        a {
          &:before {
            background-image: url(../../images/icon-benevole.png);
            background-size: 26px;

            @include sm {
              background-image: url(../../images/icon-benevole@2x.png);
            }
          }
        }
      }

      &.entree--handicap {
        a {
          &:before {
            background-image: url(../../images/icon-handicap.png);
            background-size: 33px;

            @include sm {
              background-image: url(../../images/icon-handicap@2x.png);
            }
          }
        }
      }

      &.entree--parent {
        a {
          &:before {
            background-image: url(../../images/icon-parent.png);
            background-size: 38px;

            @include sm {
              background-image: url(../../images/icon-parent@2x.png);
            }
          }
        }
      }

      &.entree--entreprise {
        a {
          &:before {
            background-image: url(../../images/icon-entreprise.png);
            background-size: 36px;

            @include sm {
              background-image: url(../../images/icon-entreprise@2x.png);
            }
          }
        }
      }

      &.entree--recherche-emploi {
        a {
          &:before {
            background-image: url(../../images/icon-emploi.png);
            background-size: 33px;

            @include sm {
              background-image: url(../../images/icon-emploi@2x.png);
            }
          }
        }
      }

      &.entree--professionnel {
        a {
          &:before {
            background-image: url(../../images/icon-professionnel.png);
            background-size: 35px;

            @include sm {
              background-image: url(../../images/icon-professionnel@2x.png);
            }
          }
        }
      }


      &.entree--first {
        display: flex;
        font-size: 1.6rem;
        color: $colorBlack;
        cursor: pointer;
        border-width: 2px;
        border-color: $colorDarkGray;
        height: 49px;
        letter-spacing: 0;
        padding: 0 11px;
        position: relative;
        opacity: 1;
        box-shadow: none;
        pointer-events: auto;

        &:after {
          content: "";
          background-image: url(../../images/vous-etes.png);
          width: 14px;
          height: 23px;
          display: inline-block;
          position: absolute;
          right: 11px;
          top: 11px;

          @include sm {
            background-image: url(../../images/vous-etes@2x.png);
            background-size: 42px;
          }
        }

        &:hover {
          color: $colorWhite;
          border-color: $colorBlue;
          background-color: $colorBlue;

          &:after {
            background-position: -14px 0;
          }
        }
      }
    }
  }
}

#block-system-main-menu {
  margin-top: 34px;
  position: relative;

  .domain-sanslimites & {
    margin-top: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: 0;
    margin: 0;
    list-style-image: none;
    list-style-type: none;

    a,
    span {
      align-items: center;
      display: flex;
      font-family: $fontDroidSans;
      font-weight: bold;
      color: $colorBlue1;
      font-size: 2.5rem;
      min-height: 62px;
      line-height: 62px;
      border-bottom: 2px solid $colorLightGray2;
      @include transition;

      &:hover,
      &:focus {
        background-color: $colorLightGray2;
        padding-left: 10px;
      }
    }

    &.last {
      a,
      span {
        border-bottom: 0;
      }
    }

    &.expanded {
      position: relative;

      &:after {
        content: "";
        background-image: url(../../images/icon-next-gray.png);
        background-size: 12px;
        background-position: center;
        background-repeat: no-repeat;
        width: 12px;
        height: 21px;
        display: block;
        position: absolute;
        right: 12px;
        top: 20px;
        transform: rotate(90deg);
        pointer-events: none;
        @include transition;

        @include sm {
          background-image: url(../../images/icon-next-gray@2x.png);
        }
      }

      &.collapsed {
        &:after {
          transform: rotate(0deg);
        }
      }
    }

    > ul {
      > li {
        a {
          background-color: $colorLightGray2;
          font-weight: normal;
          font-size: 2.2rem;
          border-color: $colorLightGray1;
          padding: 0 20px;
          min-height: 51px;
          line-height: 51px;

          &:hover {
            background-color: $colorGray2;
            padding-left: 20px;
          }
        }
      }
    }

    // Couleurs spéciales pour la police des items de menu pouvant être choisies par l'utilisateur.
    &.blanc {
      a, span {
        color: $colorWhite;
      }
    }

    &.noir {
      a, span {
        color: $colorBlack;
      }
    }

    &.jaune {
      a, span {
        color: $colorYellow;
      }
    }

    &.violet {
      a, span {
        color: $colorViolet;
      }
    }

    &.bg-blanc {
      a, span {
        background-color: $colorWhite;
        padding-left: 20px;
      }
    }

    &.bg-bleue {
      a, span {
        background-color: $colorBlue;
        padding-left: 20px;
      }

      &:after {
        background-image: url('/sites/all/themes/ladapt/images/icon-next-white.png');
      }
    }

    &.bg-noir {
      a, span {
        background-color: $colorBlack;
        padding-left: 20px;
      }

      &:after {
        background-image: url('/sites/all/themes/ladapt/images/icon-next-white.png');
      }
    }

    &.bg-jaune {
      a, span {
        background-color: $colorYellow;
        padding-left: 20px;
      }
    }

    &.bg-violet {
      a, span {
        background-color: $colorViolet;
        padding-left: 20px;
      }

      &:after {
        background-image: url('/sites/all/themes/ladapt/images/icon-next-white.png');
      }
    }
  }
}

header.sticky {
  #menu-principal {
    @include min($sm-width) {
      padding-top: 76px;
    }
  }

  @include sm {
    #menu-principal {
      display: none;
      margin-top: 110px;
    }

    &.show-header {
      #menu-principal {
        display: block;
      }
    }
  }
}