%animated {
  animation-duration: 0.4s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes sticky-menu-slide {
    0% {top: -100px; opacity: 0;}
    100% {top: 0px; opacity: 1;}
}

%sticky-menu-slide {
  animation-name: sticky-menu-slide;
}

@keyframes search-mobile-slide-down {
    0% {margin-top: 0px; opacity: 0;}
    100% {margin-top: 132px; opacity: 1;}
}

%search-mobile-slide-down {
  animation-name: search-mobile-slide-down;
}

@keyframes search-mobile-slide-up {
    0% {margin-top: 132px; opacity: 1;}
    100% {margin-top: 0px; opacity: 0;}
}

%search-mobile-slide-up {
  animation-name: search-mobile-slide-up;
}

@keyframes search-mobile-slide-down-sticky {
    0% {margin-top: 0px; opacity: 0;}
    100% {margin-top: 110px; opacity: 1;}
}

%search-mobile-slide-down-sticky {
  animation-name: search-mobile-slide-down-sticky;
}

@keyframes search-mobile-slide-up-sticky {
    0% {margin-top: 110px; opacity: 1;}
    100% {margin-top: 0px; opacity: 0;}
}

%search-mobile-slide-up-sticky {
  animation-name: search-mobile-slide-up-sticky;
}

@keyframes slide-down {
    0% {margin-top: -100%; opacity: 0;}
    100% {margin-top: 0; opacity: 1;}
}

%slide-down {
  animation-name: slide-down;
}

@keyframes slide-up {
    0% {margin-top: 0; opacity: 1;}
    100% {margin-top: -100%; opacity: 0;}
}

%slide-up {
  animation-name: slide-up;
}

@keyframes fade-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

%fade-in,
.fade-in {
  animation-name: fade-in;
}

@keyframes fade-out {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

%fade-out,
.fade-out {
  animation-name: fade-out;
}


@keyframes slide-in {
    0% {margin-left: -100%; opacity: 0;}
    100% {margin-left: 0; opacity: 1;}
}

%slide-in {
  animation-name: slide-in;
}
