////////////////////////
// HTML elements
//
// Override here the prime rules.
//
////////////////////////
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;

  &.no-scroll {
    overflow-y: hidden;

    body {
      overflow-y: hidden;
    }
  }
}

body {
  background-color: $colorWhite;
  color: $colorDarkGray;
  cursor: default;
  font-size: 1.6rem !important;
  font-family: $fontRoboto;
  line-height: 1.2;
  overflow-x: hidden;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;

  @include sm {
    font-size: 2.7rem !important;
  }

  @include xs {
    font-size: 1.6rem !important;
  }

  &.fixed {
    position: fixed;
    width: 100%;
  }

  &.textsize-100 {
    font-size: 1.6rem !important;

    @include sm {
      font-size: 2.7rem !important;
    }

    @include xs {
      font-size: 1.6rem !important;
    }
  }
}

img {
  max-width: 100%;
}

a {
  color: inherit;
}

.section {
  @extend %container;
  @extend %clearfix;
}

#main-content {
  max-width: 100%;
  width: 100%;
  padding-bottom: 100px;

  @include sm {
    padding-bottom: 164px;
  }

  @include xs {
    padding-bottom: 100px;
  }
}

#page-title {
  padding: 62px 0;

  @include sm {
    padding: 28px 0;
  }

  h1 {
    font-family: $fontDroidSans;
    background-color: $colorBlue1;
    color: $colorWhite;
    display: inline-block;
    line-height: 1;
    font-size: 5rem;
    margin: 0;
    padding: 10px 25px;

    @include sm {
      font-size: 6rem;
    }

    @include xs {
      font-size: 5rem;
    }
  }
}

picture {
  font-size: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $fontRoboto;
}

.ui-widget select {
  font-size: 1.6rem;
}

.form-item, .form-actions {
    margin-top: 0;
    margin-bottom: 0;
}

.form-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 26px;

  &.form-type-radio,
  &.form-type-checkbox {
    display: block;
  }

  @include sm {
    margin-bottom: 41px;
    position: relative;
  }

  label {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 8px;
    flex: 0 1 100%;
    color: $colorBlack;

    @include sm {
      font-size: 2.8rem;
      margin-bottom: 18px;
    }

    @include xs {
      font-size: 1.8rem;
      margin-bottom: 8px;
    }
  }

  input.error,
  textarea.error,
  select.error {
    border-color: $colorDarkRed;
    color: $colorDarkRed;

    & + .description {
      background-color: $colorDarkRed;
      position: relative;

      @include sm {
        position: absolute;
      }

      &:before {
        content: "";
        position: absolute;
        border: 10px solid $colorDarkRed;
        border-color: transparent $colorDarkRed transparent transparent;
        left: 0;
        top: 50%;
        margin: -10px 0 0 -20px;

        @include sm {
          display: none;
        }
      }

      &:after {
        border-color: $colorDarkRed transparent transparent transparent;
      }

      & + .info-button {
        background-color: $colorDarkRed;
      }
    }
  }
}

#user-login label {
  position: static;
  display: block !important;
  opacity: 1 !important;
}

input {
  background-color: $colorWhite;
  height: 40px;
  font-weight: normal;
  color: $colorBlack;
  max-width: 100%;

  @include sm {
    height: 70px;
    font-size: 2.6rem;
  }

  @include xs {
    height: 40px;
    font-size: 1.6rem;
  }

  &.form-text {
    border: 2px solid $colorDarkGray3;
    padding: 0 14px;
    max-width: calc(100vw - 40px);

    &.have-description {
      width: calc(60% - 20px);
      margin-right: auto;

      @include sm {
        width: 83%;
      }

      @include xs {
        width: calc(100% - 60px;)
      }

      &.form-number {
        width: auto;

        @include sm {
          max-width: 80%;
        }

        @include xs {
          max-width: 100%;
          width: calc(100% - 60px;)
        }
      }
    }
  }
}

input#edit-submitted-identifiant-email,
input#edit-name {
  background-image: url(../../images/icon-user.png);
  background-position: left 10px center;
  background-repeat: no-repeat;
  padding-left: 40px;

  @include sm {
    background-image: url(../../images/icon-user@2x.png);
    background-position: left 17px center;
    background-size: 40px;
    padding-left: 74px;
  }

  @include xs {
    background-size: 20px;
    background-position: left 10px center;
    padding-left: 40px;
  }
}

input#edit-submitted-identifiant-password,
input#edit-pass {
  background-image: url(../../images/icon-lock.png);
  background-position: left 11px center;
  background-repeat: no-repeat;
  padding-left: 40px;

  @include sm {
    background-image: url(../../images/icon-lock@2x.png);
    background-position: left 19px center;
    background-size: 36px;
    padding-left: 74px;
  }

  @include xs {
    background-size: 18px;
    background-position: left 11px center;
    padding-left: 40px;
  }
}

.form-item .description {
  display: flex;
  align-items: center;
  width: 40%;
  background-color: $colorDarkGray3;
  color: $colorWhite;
  padding: 0 16px;
  font-size: 1em;
  margin-left: auto;

  @include sm {
    font-size: 2.6rem;
    position: absolute;
    right: 0;
    bottom: 85px;
    width: auto;
    max-width: 60%;
    padding: 5px 16px;
    visibility: hidden;

    &.fade-in {
      visibility: visible;
      animation-duration: 0.4s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 10px solid $colorDarkGray3;
      border-color: $colorDarkGray3 transparent transparent transparent;
      right: 25px;
      bottom: 0;
      margin-bottom: -20px;
      position: absolute;
    }
  }

  @include xs {
    bottom: 50px;
    padding: 5px 16px;
    font-size: 1.6rem;
    max-width: 80%;

    &:after {
      border: 7px solid $colorDarkGray3;
      border-color: $colorDarkGray3 transparent transparent transparent;
      right: 14px;
      bottom: 0;
      margin-bottom: -14px;
    }
  }
}

.info-button {
  width: 70px;
  height: 70px;
  display: none;
  color: $colorWhite;
  background-color: $colorDarkGray3;
  background-image: url(../../images/icon-info.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
  @include transition;

  &:hover {
    background-color: $colorBlack;
  }

  @include sm {
    display: block;
  }

  @include xs {
    width: 40px;
    height: 40px;
    background-size: 6px;
  }
}

.form-type-radio,
.form-type-checkbox {
  margin: 0 0 21px;

  @include sm {
    margin: 0 0 31px;
  }

  @include xs {
    margin: 0 0 21px;
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    filter:alpha(opacity=0);
  }

  label {
    display: flex;
    font-size: 1.6rem;
    font-weight: normal;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
    .webform-client-form & {
      // @todo Léo fix bug on /formulaire-faire-un-don
      // there is no space between <strong> and normal text.
      display: -webkit-box;
    }

    @include sm {
      font-size: 2.6rem;
    }

    @include xs {
      font-size: 1.6rem;
    }

    &:before {
      content: "";
      background-image: url(../../images/radio-sprite.png);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 46px;
      display: block;
      margin-right: 16px;
      width: 23px;
      height: 23px;

      @include sm {
        background-image: url(../../images/radio-sprite@2x.png);
        background-size: 70px;
        margin-right: 30px;
        width: 35px;
        height: 35px;
      }

      @include xs {
        background-size: 46px;
        margin-right: 16px;
        width: 23px;
        height: 23px;
      }
    }
  }

  input:focus + label {
    &:before {
      box-shadow: 0 0 8px rgba($colorBlack, 0.6);
    }
  }

  input:checked + label {
    &:before {
      background-position: -23px 0;

      @include sm {
        background-position: -35px 0;
      }

      @include xs {
        background-position: -23px 0;
      }
    }
  }

  input[value*="disabled"] + label {
    opacity: 0.3;
    pointer-events: none;
  }
}

.form-type-radio {
  input:focus + label {
    &:before {
      border-radius: 50%;
    }
  }
}

.form-type-checkbox {
  label::before {
    background-image: url(../../images/checkbox-sprite.png);

    @include sm {
      background-image: url(../../images/checkbox-sprite@2x.png);
    }
  }
}

.form-radios,
.form-checkboxes {
  margin-top: 22px;

  @include sm {
    margin-top: 42px;
  }

  @include xs {
    margin-top: 22px;
  }
}

.form-submit {
  @extend %yellow-button;
  width: 167px;
  height: 56px;
  margin-right: 0;
}

select {
  height: 40px;
  cursor: pointer;
  border: 2px solid $colorDarkGray;
  color: $colorBlack;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0 40px 0 13px;
  background-image: url(../../images/select.png);
  background-position: right 13px center;
  background-repeat: no-repeat;
  max-width: 100%;

  @include sm {
    font-size: 2.6rem;
    height: 70px;
    background-image: url(../../images/select@2x.png);
    background-size: 19px;
  }

  @include xs {
    width: 100%;
    font-size: 1.6rem;
    height: 40px;
    background-size: 14px;
    margin-bottom: 10px;
  }
}

textarea {
  border: 2px solid $colorDarkGray3;
  padding: 12px 14px;
  color: $colorBlack;

  @include sm {
    font-size: 2.6rem;
  }

  @include xs {
    font-size: 1.6rem;
  }
}

.webform-component-fieldset {
  border: 0;
  background-color: $colorLightGray4;
  padding: 29px;
  margin-bottom: 26px;

  @include sm {
    padding: 39px 20px;
    margin: 0 -20px 26px;
  }

  @include xs {
    padding: 29px 20px;
  }

  legend {
    display: block;
    float: left;
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: letter-spacing(50);
    margin: 0 0 29px;
    padding: 0;
    color: $colorBlue;

    @include sm {
      font-size: 3rem;
    }

    @include xs {
      font-size: 2rem;
    }

    + * {
        clear: both;
    }
  }
}

fieldset.captcha {
  padding: 0;
  margin: 0;
  border: 0;

  legend {
    display: none;
  }

  .fieldset-description {
    font-size: 1.8rem;
    font-weight: 500;
    color: $colorBlack;
    margin-bottom: 19px;

    @include sm {
      font-size: 2.8rem;
    }

    @include xs {
      font-size: 1.8rem;
    }
  }
}

input.webform-calendar {
  background-image: url(../../images/icon-calander.png);
  background-position: left 16px center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: $colorBlue;
  padding-left: 62px;
  width: 62px;
  outline: 0;

  @include sm {
    background-image: url(../../images/icon-calander@2x.png);
    background-size: 42px;
    background-position: left 25px center;
    width: 92px;
    padding-left: 92px;
  }

  @include xs {
    width: 100%;
    padding: 0 0 0 100%;
    background-size: 30px;
    background-position: center;
  }
}

#ui-datepicker-div {
  width: 336px;
  max-width: 100%;
  background-color: $colorWhite;
  border: 0;
  border-radius: 0;
  font-family: $fontRoboto;
  font-size: 1.8rem;
  padding: 0;
  box-shadow: 0 13px 29px rgba($colorBlack, 0.35);
  color: $colorBlue;
  margin-top: -40px;
  padding: 0 10px;

  @include xs {
    left: 0 !important;
  }
}

.ui-datepicker .ui-datepicker-header {
  background-color: $colorBlue;
  background-image: none;
  color: $colorWhite;
  border: 0;
  font-size: 2rem;
  text-transform: uppercase;
  border-radius: 0;
  height: 64px;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 -10px;

  .ui-datepicker-title {
    margin: 0 auto;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    top: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    border-radius: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      border: 0;
    }

    .ui-icon {
      background: none;
      font-size: 0;
      color: transparent;
      text-indent: 0;
      font-weight: normal;
      margin: 0;
      position: static;
      line-height: 1;
      width: auto;
      height: auto;

      &:before {
        font-family: FontAwesome;
        color: $colorWhite;
        font-size: 2.1rem;
      }
    }
  }

  .ui-datepicker-prev {
    left: 0;

    span {
      &:before {
        content: "\f104";
      }
    }
  }

  .ui-datepicker-next {
    right: 0;

    span {
      &:before {
        content: "\f105";
      }
    }
  }
}

.ui-datepicker-calendar {
  margin-bottom: 0;

  th {
    padding: 12px 10px;
    font-size: 1.8rem;
    font-weight: 500;
    border: 0;
    text-align: left;
  }

  tbody {
    border: 0;

    td {
      padding: 12px 10px;
      font-size: 1.8rem;

      .ui-state-default {
        border: 0;
        background: none;
        font-weight: 500;
        color: $colorBlue;
        padding: 0;
        text-align: left;
      }

      &.ui-datepicker-unselectable {
        span {
          color: $colorGray;
        }
      }

      .ui-state-active {
        color: $colorBlue4;
      }
    }
  }
}

.form-managed-file {
  display: inline-flex !important;
  align-items: center;

  @include sm {
    flex-wrap: wrap;
  }

  .file {
    display: inline-flex;
    align-items: center;
    color: $colorBlack;

    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
      margin-right: 17px;

      @include sm {
        width: 125px;
        height: 125px;
      }

      @include xs {
        width: 60px;
        height: 60px;
      }
    }
  }

  input,
  a {
    @include sm {
      font-size: 2.6rem;
    }

    @include xs {
      font-size: 1.6rem;
    }
  }

  .form-submit {
    height: 40px;
    background-color: $colorDarkGray;
    color: $colorWhite;
    padding: 0 25px;
    display: inline-block;
    margin-left: 17px;
    cursor: pointer;

    @include sm {
      height: 70px;
      margin: 10px 0;
    }

    @include xs {
      height: 40px;
    }
  }

  .file + .form-submit {
    background-image: url(../../images/icon-close@2x.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 90px;
    background-color: transparent;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    padding: 0;

    &:hover {
      background-position: -30px 0;
    }

    @include sm {
      background-size: 174px;
      width: 58px;
      height: 58px;
      margin-left: 17px;

      &:hover {
        background-position: -58px 0;
      }
    }

    @include xs {
      background-size: 90px;
      width: 30px;
      height: 30px;

      &:hover {
        background-position: -30px 0;
      }
    }
  }
}

.form-actions {
  margin-top: 62px;

  .form-submit {
    @extend %blue-border-button;
    cursor: pointer;
    height: 59px;
    font-size: 1.7rem;
    font-weight: bold;
    letter-spacing: letter-spacing(300);
    min-width: 263px;
    padding: 0 40px;
    display: inline-block;

    @include sm {
      height: 89px;
      font-size: 2.7rem;
      min-width: 66.66%;
    }

    @include xs {
      height: 59px;
      font-size: 1.7rem;
      min-width: 100%;
    }
  }
}

.form-required {
  color: $colorPink;
}

iframe[name="google_conversion_frame"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

div.messages {
  background-position: left 10px center;
  background-repeat: no-repeat;
  border: 0;
  padding: 18px 10px 24px 76px;
  min-height: 56px;

  @include sm {
    background-position: center top 10px;
    padding: 76px 20px 20px;
    width: 100%;
  }
}

#messages {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  color: $colorWhite;
  font-size: 1.8rem;
  font-weight: 500;
  box-shadow: 0 4px 0 rgba($colorBlack, 0.24);
  @extend %animated;
  @extend %slide-down;

  &.slide-up {
    @extend %slide-up;
  }

  .section {
    display: flex;
    align-items: center;

    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .fermer-button {
    border: 2px solid #fff;
    width: 158px;
    height: 52px;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0 12px 0 30px;
    margin-left: auto;
    position: relative;
    z-index: 1;
    @include transition;

    &:after {
      content: "";
      background-image: url(../../images/icon-close.png);
      background-repeat: no-repeat;
      background-position: -66px 0;
      background-size: 99px;
      display: block;
      width: 33px;
      height: 33px;
      margin-left: 15px;
    }

    &:hover {
      background-color: $colorWhite;
      color: $colorBlue;

      &:after {
        background-position: 0 0;
      }
    }

    @include sm {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  div.messages {
    margin: 0;
    padding: 18px 0 24px 76px;
    background-position: left center;

    &:before {
      width: 3000% !important;
      left: -1000% !important;
    }

    &:after {
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      width: 56px;
      height: 56px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -28px;
      z-index: 1;
    }

    @include sm {
      background-position: center top;
      padding: 76px 20px 92px;
      width: 100%;

      &:after {
        left: 50%;
        top: 0;
        margin-left: -28px;
        margin-top: 10px;
      }
    }
  }

  div.error, table tr.error {
    @include fullWidthBackground($colorDarkRed);

    &:after {
      background-image: url(../../images/icon-error.png);
    }
  }

  div.status, table tr.status {
    @include fullWidthBackground($colorSuccessMessage);

    &:after {
    }
  }

  div.warning, table tr.warning {
    @include fullWidthBackground($colorWarningMessage);

    &:after {
    }
  }
}

div.error, table tr.error {
  background-image: url(../../images/icon-error.png);
  background-color: $colorDarkRed;
}

div.status, table tr.status {
  background-color: $colorSuccessMessage;
}

div.warning, table tr.warning {
  background-color: $colorWarningMessage;
}

div.messages {
  color: $colorWhite;
}

#sliding-popup {
  .popup-content {
    #popup-text {
      display: inline-block;
      margin: 0 auto;
      max-width: 70%;
      p {
        display: inline-block;
        font-weight: normal;
        font-size: 2rem;
        line-height: 1.8;
      }
    }
  }
}


