.node-type-evenement {
  #page-title {
    display: none;
  }

  .field-type-evenement {
    line-height: 2.5rem;
  }

  .wrapper-contact {
    margin-top: 2rem;
    padding-bottom: 2rem;

    .contact-button {
      padding: 11px 17px;
      background-color: $colorBlue1;
      color: $colorWhite;
      display: inline-flex;
    }

    .modal {
      display: none;
      position: fixed;
      z-index: 999;
      padding: 20% 10% 0 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgb(0,0,0);
      background-color: rgba(0,0,0,0.4);

      .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 25%;

        a {
          display: block;
          margin-bottom: 10px;
          color: $colorBlue1;

          span {
            float: left;
            font-weight: bold;
            font-size: 16px;
            color: $colorBlue1;
            margin-left: 3.5rem;
          }

          div {
            color: $colorBlack;
          }
        }
      }
    }
  }

  .wrapper-content-adresse {
    .thoroughfare {
      &:after {
        content: ", ";
      }
    }
  }

  .wrapper-content-types-thematiques {
    &:before {
      content: "";
      display: block;
      margin: 17px 0 23px;
      width: 83.33%;
      height: 2px;
      background-color: $colorLightGray8;
    }

    ul {
      display: inline-flex !important;
      li {
        margin-right: 1.5rem !important;
        color: $colorBlue1;
        font-size: 1.8rem !important;
        font-weight: 500;

        &:before {
          content: "" !important;
        }
      }
    }
  }

  .wrapper-content-thematiques {
    line-height: 0;
    ul {
      display: inline-flex !important;

      li {
        color: $colorWhite;

        &:before {
          content: "" !important;
        }

        &:not(:last-child) {
          &:after {
            content: ", ";
            padding-right: 5px;
          }
        }
      }
    }
  }
}
