html body.admin-menu {
  margin-top: 0 !important;
}

.domain-sanslimites {
  #logo {
    display: flex;
    align-items: flex-end;

    @include sm {
      flex-wrap: wrap;
      justify-content: center;
      width: 150px;

      img {
        height: 50px;
      }
    }

    @include xs {
      margin: 0;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    .site-name {
      width: 200px;
      font-weight: bold;
      font-size: 2.9rem;
      color: $colorBlue2;

      @include sm {
        width: 100%;
        font-size: 2rem;
        text-align: center;

        strong {
          display: block;
        }
      }

      @include xs {
        text-align: left;
      }

      &::first-line {
        font-weight: normal;
        letter-spacing: letter-spacing(80);
      }
    }
  }

  .faire-un-don {
    background-color: $colorYellow;

    @include sm {
      padding: 0 10px;
    }

    .title {
      color: $colorBlack;
    }
  }

  #header {
    @include xs {
      .hamburger button {
        text-align: center;

        .hamburger-icon {
          float: none;
        }
      }
    }

    &:not(.sticky) {
      #navigation {
        margin-right: 10.5%;

        @include sm {
          margin-right: auto;
        }

        @include xs {
          margin-right: 20px;
        }
      }

      .faire-un-don {
        margin-left: 0;
      }
    }

    &.sticky {
      #navigation {
        margin-right: 18.7%;
      }

      .site-name {
        display: none;
      }
    }
  }

  #breadcrumb {
    display: none;
  }

  #main-content {
    .header {
      position: relative;
      display: flex;
      height: 669px;

      @include sm {
        flex-wrap: wrap;
        height: auto;
        background-color: $colorLightGray;
        padding-bottom: 30px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 411px;
        background-color: $colorLightGray;

        @include sm {
          display: none;
        }
      }

      &-texte {
        position: absolute;
        bottom: 142px;
        left: calc(((100% - 1280px) / 2) + 20px);
        width: calc(#{$container-width} / 2);
        padding: 60px 40px 10px 85px;
        background-color: $colorWhite;
        font-size: 3.8rem;
        color: $colorBlack;
        text-transform: uppercase;
        text-align: right;
        z-index: 1;

        @include sm {
          order: 2;
          position: static;
          width: calc(100% - 20px);
          max-width: 500px;
          margin-top: -60px;
          margin-left: auto;
          padding: 20px;
          font-size: 2.8rem;
        }

        strong {
          color: $colorBlue;
        }
      }

      &-image {
        position: absolute;
        right: 0;
        top: 0;
        width: calc((#{$container-width} * 0.55) + ((100% - 1280px) / 2));

        @include sm {
          order: 1;
          position: static;
          width: 100%;
        }

        img {
          display: block;
          width: 100%;
          height: 669px;
          object-fit: cover;

          @include sm {
            height: 50vh;
          }
        }
      }
    }

    .enjeu-democratie {
      .enjeu-intro {
        margin: 70px 0 0;
        padding-bottom: 270px;

        @include sm {
          margin: 20px 0 0;
          padding: 0;
        }

        div {
          color: $colorWhite;
          text-transform: uppercase;
          font-weight: bold;
          text-align: center;

          span {
            display: inline-block;
            padding: 8px 30px;
          }

          &:first-of-type {
            margin-bottom: 10px;

            span {
              background-color: $colorBlue5;
              font-size: 3.2rem;

              @include sm {
                max-width: calc(100% - 20px);
                margin: 0 auto;
                font-size: 2.2rem;
              }
            }
          }

          &:last-of-type span {
            background-color: $colorMint1;
            font-size: 5rem;

            @include sm {
              max-width: calc(100% - 40px);
              margin: 0 auto;
              font-size: 3rem;
            }
          }
        }
      }

      .enjeu-images {
        margin-top: -200px;
        padding-bottom: 200px;

        @include sm {
          margin-top: 20px;
          padding-bottom: 0;
        }

        .container {
          @extend %container;
        }

        .wrapper.top {
          .container {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .left {
              position: relative;
              width: 33.3333%;
              padding-bottom: 33.3333%;
              max-width: 400px;

              .france-map {
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(../../images/map-france.png);
                background-position: top center;
                background-size: 100%;
                background-repeat: no-repeat;
              }
            }

            .right {
              padding-left: 20px;

              @include sm {
                max-width: 66.6666%;
              }
            }

            .data {
              width: 505px;
              padding: 26px 20px 80px;
              background-color: $colorWhite;

              @include sm {
                width: auto;
                padding: 20px;
              }

              .number {
                font-size: 8.3rem;
                font-weight: bold;
                color: $colorBlack;

                @include sm {
                  font-size: 5rem;
                }

                &::after {
                  content: "";
                  display: block;
                  width: 52px;
                  height: 6px;
                  margin: 10px 0;
                  background-color: $colorLightGray1;

                  @include sm {
                    width: 30px;
                    height: 4px;
                  }
                }
              }

              .description {
                font-size: 2.8rem;
                font-weight: bold;
                color: $colorBlue;
                text-transform: uppercase;

                @include sm {
                  font-size: 1.8rem;
                }

                strong {
                  color: $colorBlack;
                  font-size: 3.5rem;

                  @include sm {
                    font-size: 2.4rem;
                  }
                }
              }
            }
          }
        }

        .wrapper.bottom {
          margin-top: -180px;
          padding: 170px 0 30px;
          background-color: $colorLightGray;

          @include sm {
            margin-top: -40px;
            padding-top: 60px;
          }

          .statistic-image {
            width: 83.3333%;
            height: 405px;
            margin-left: auto;
            background-image: url(../../images/bg-statistic-image.png);
            background-position: right center;
            background-size: 100%;
            background-repeat: no-repeat;

            @include md {
              height: 30vw;
            }

            @include sm {
              width: 100%;
              height: 50vw;
            }
          }
        }
      }

      .enjeu-encarts {
        @extend %container;
        display: flex;
        padding: 0 10px;
        margin-top: -150px;

        @include sm {
          margin-top: -30px;
        }

        @include sm {
          flex-wrap: wrap;
        }

        .emploi {
          width: calc(50% - 20px);
          margin: 0 10px;
          padding: 45px;
          background-color: $colorWhite;
          text-align: center;

          @include sm {
            padding: 20px;
          }

          @include xs {
            width: 100%;
          }

          header {
            font-size: 2.6rem;
            font-weight: bold;

            @include sm {
              font-size: 2rem;
            }

            &::after {
              content: "";
              display: block;
              margin: 15px auto;
              width: 60px;
              height: 6px;
              background-color: rgba($colorBlack, 0.2);
            }
          }

          .content {
            font-size: 2rem;
            color: $colorBlack;

            @include sm {
              font-size: 1.6rem;
            }
          }

          hr {
            margin: 12px 0;
            border: 0;
            border-bottom: 2px dotted $colorBlack;
          }

          strong {
            font-size: 2.6rem;
          }

          &:nth-of-type(1) {
            header,
            strong {
              color: $colorDarkMint;
            }
          }

          &:nth-of-type(2) {
            header,
            strong {
              color: $colorViolet;
            }
          }
        }
      }
    }
  }

  .parallax-autonomie {
    background-color: $colorLightGray;
    padding-bottom: 20px;

    .image {
      width: 100%;
      height: 472px;
      background-image: url(../../images/bg-autonomie.png);
      background-position: center;
      background-size: cover;
      background-attachment: fixed;

      @include md {
        background-attachment: scroll;
        height: 50vw;
      }
    }

    .text {
      margin-top: -30px;

      div {
        color: $colorWhite;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;

        span {
          display: inline-block;
          padding: 8px 30px;

          @include sm {
            padding: 8px 10px;
          }
        }

        &:first-of-type {
          margin-bottom: 10px;

          span {
            background-color: $colorBlack;
            font-size: 3.2rem;

            @include sm {
              width: calc(100% - 20px);
              margin: 0 auto;
              font-size: 2.2rem;
            }
          }
        }

        &:last-of-type span {
          background-color: $colorViolet;
          font-size: 3.9rem;

          @include sm {
            width: calc(100% - 40px);
            margin: 0 auto;
            font-size: 2.9rem;
          }
        }
      }
    }
  }

  .field-encarts {
    @extend %container;

    hr {
      display: none;
    }

    > div {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 40px 0;
      @include fullWidthBackground($colorLightGray);

      @include sm {
        flex-wrap: wrap;
        padding: 20px 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        height: calc(100% - 80px);
        background-color: $colorWhite;
        z-index: -1;

        @include sm {
          top: 20px;
          height: calc(100% - 40px);
        }
      }

      .encart {
        &-title {
          order: 1;

          width: 25%;
          max-width: 295px;
          display: flex;
          padding: 20px;
          justify-content: center;
          align-items: center;
          background-color: $colorMint1;
          text-align: center;
          text-transform: uppercase;
          color: $colorWhite;
          font-size: 2.9rem;
          font-weight: bold;

          @include sm {
            width: 100%;
            max-width: none;
          }
        }

        &-texte {
          order: 2;

          width: 25%;
          max-width: 295px;
          display: flex;
          padding: 50px 20px 0;
          text-align: center;
          font-size: 2.5rem;
          color: $colorBlack;

          @include sm {
            width: 100%;
            max-width: none;
            padding: 20px;
          }
        }

        &-cta {
          order: 3;

          position: absolute;
          left: 25%;
          bottom: 70px;
          width: 25%;
          max-width: 295px;
          text-align: center;

          @include sm {
            width: 100%;
            max-width: none;
            position: static;
          }

          a {
            display: inline-block;
            max-width: 234px;
            margin-top: 20px;
            padding: 20px 50px;
            border: 3px solid $colorDarkGray1;
            color: $colorDarkGray1;
            font-size: 1.8rem;
            font-weight: bold;
            @include transition;

            @include sm {
              max-width: 100%;
              padding: 10px 20px;
            }

            &:hover {
              background-color: $colorDarkGray1;
              color: $colorWhite;
            }
          }
        }

        &-chiffre {
          order: 4;

          width: 16.6666%;
          max-width: 210px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include sm {
            width: 100%;
            max-width: none;
            padding: 20px 20px 0;
          }

          > * {
            background-color: $colorLightGray;
            padding: 0 5px;
          }

          h3 {
            margin: 0;
            padding-top: 35px;
            font-size: 1.7rem;
            font-weight: normal;
            text-align: center;
            text-transform: uppercase;

            &::after {
              content: "";
              display: block;
              width: 42px;
              height: 6px;
              margin: 15px auto;
              background-color: $colorLightGray6;
            }
          }

          > .chiffre {
            padding-bottom: 35px;
            font-weight: bold;
            font-size: 3.2rem;
            color: $colorMint1;
            text-align: center;
            line-height: 1;

            .chiffre {
              display: block;
              font-size: 4.7rem;
            }
          }
        }

        &-visuel {
          order: 5;

          width: 33.3333%;
          max-width: 400px;
          padding-left: 20px;

          @include sm {
            width: 100%;
            max-width: none;
            padding: 20px 0 0;
          }

          img {
            display: block;
            width: 100%;
            height: 326px;
            object-fit: cover;
          }
        }
      }

      @include min(769px) {
        &:nth-of-type(2n) {
          &::before {
            display: none;
          }

          &::after {
            background-color: $colorLightGray;
          }

          .encart {
            &-title {
              order: 5;
            }

            &-texte {
              order: 3;
            }

            &-cta {
              order: 4;
              left: auto;
              right: 25%;
            }

            &-chiffre {
              order: 2;

              > * {
                background-color: $colorWhite;
              }
            }

            &-visuel {
              order: 1;

              padding-left: 0;
              padding-right: 20px;
            }
          }
        }
      }

      &:nth-of-type(2) {
        .encart {
          &-title {
            background-color: $colorBlue7;
          }
        }

        .chiffre {
          color: $colorBlue7;
        }
      }

      &:nth-of-type(3) {
        .encart {
          &-title {
            background-color: $colorViolet;
          }
        }

        .chiffre {
          color: $colorViolet;
        }
      }

      &:nth-of-type(4) {
        .encart {
          &-title {
            background-color: $colorBlue8;
          }
        }

        .chiffre {
          color: $colorBlue8;
        }
      }
    }
  }

  .reseau {
    @extend %container;
    display: flex;
    padding: 75px 0 85px;
    flex-wrap: wrap;
    @include fullWidthBackground($colorBlue);
    font-size: 4.3rem;
    color: $colorWhite;
    text-align: center;
    justify-content: center;

    @include sm {
      font-size: 3rem;
    }

    strong {
      color: $colorYellow;
    }

    .wrapper {
      display: flex;
      width: 100%;
      margin: 60px 0 0;
      padding: 0 20px;
      flex-wrap: wrap;

      .left,
      .right {
        width: 50%;

        @include sm {
          width: 100%;
        }
      }

      .left {
        display: flex;

        @include sm {
          order: 2;
        }
      }

      .text {
        position: relative;
        width: 544px;
        padding: 45px 20px 40px;
        margin: auto 0 60px auto;
        background-color: $colorWhite;
        font-size: 2.8rem;
        color: $colorBlack;
        text-align: right;
        box-shadow: 0 0 24px rgba($colorBlack, 0.24);
        z-index: 1;

        @include sm {
          padding: 20px;
          margin: 0;
        }

        strong {
          color: $colorBlue;
        }

        .colors {
          margin-top: 15px;

          span {
            display: inline-block;
            width: 30px;
            height: 30px;

            &:nth-of-type(1) {
              background-color: $colorMint1;
            }

            &:nth-of-type(2) {
              background-color: $colorBlue7;
            }

            &:nth-of-type(3) {
              background-color: $colorViolet;
            }

            &:nth-of-type(4) {
              background-color: $colorBlue8;
            }
          }
        }
      }

      .field-reseau-visuel {
        margin-left: -105px;

        @include sm {
          margin: 0;
        }
      }
    }

    a {
      display: inline-block;
      height: 56px;
      margin-top: 68px;
      padding: 0 2em;
      line-height: 56px;
      border-radius: 28px;
      background-color: $colorYellow;
      box-shadow: 0 0 13px rgba($colorBlack, 0.38);
      font-size: 2.2rem;
      font-weight: bold;
      color: $colorBlack;
      @include transition(background);

      @include sm {
        margin: 20px auto 0;
        max-width: calc(100% - 40px);
        font-size: 2rem;
        height: auto;
        line-height: 1.1;
        padding: 0.5em 2em;
      }

      &:hover {
        background-color: $colorWhite;
      }
    }
  }

  .campagne {
    padding: 52px 0 68px;
    background-color: $colorLightGray6;
    text-align: center;

    @include sm {
      padding: 30px 0 ;
    }

    > h2 {
      display: inline-block;
      margin-bottom: 47px;
      padding: 15px 50px 9px;
      background-color: $colorGray3;
      line-height: 1;
      color: $colorBlue;
      font-family: $fontDroidSans;
      font-size: 4.5rem;
      font-weight: bold;

      @include sm {
        max-width: calc(100% - 20px);
        padding: 15px 20px 9px;
        font-size: 3rem;
      }
    }

    .campagne-content {
      @extend %container;
      display: flex;
      padding: 0 10px;
      flex-wrap: wrap;

      .field-campagne-contenu {

        width: calc(33.3333% - 20px);

        article {

          margin: 10px;

          background-color: $colorWhite;
          box-shadow: 0 0 18px rgba($colorBlack, 0.31);

          @include sm {
            width: calc(50% - 20px);
          }

          @include xs {
            width: calc(100% - 20px);
          }

          img {
            display: block;
          }

          .content {
            display: flex;
            padding: 0 0 25px;
            flex-direction: column;
          }

          .title {
            margin: 0;
            padding: 20px;
            font-size: 2.7rem;
            line-height: 1;
            color: $colorBlue;
            font-weight: bold;
            text-align: left;
          }

          .read-more {
            margin: auto 0 0 auto;
            padding: 0 20px;

            a {
              display: inline-block;
              padding: 8px 20px;
              border: 3px solid $colorDarkGray;
              color: $colorDarkGray;
              font-size: 1.8rem;
              font-weight: bold;
              @include transition;

              &:hover {
                background-color: $colorDarkGray;
                color: $colorWhite;
              }
            }
          }
        }
      }
    }
  }

  .region-main-suffix {
    max-width: none !important;
    margin: 0 !important;
    padding: 54px 0 155px !important;
    background-image: url(../../images/bg-sengager.png);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-color: $colorBlue;

    @include sm {
      background-attachment: scroll;
      padding-bottom: 60px !important;
    }

    #block-ladapt-block-rejoignez-nous {
      @extend %container;
    }

    .content {
      h3 {
        display: inline-block;
        margin-bottom: 26px;
        padding: 0 31px;
        background-color: $colorYellow;
        font-size: 3.5rem;
        font-weight: bold;
        line-height: 6.3rem;
        text-transform: uppercase;

        @include sm {
          display: inline;
          font-size: 3rem;
          line-height: 1.2;
          padding: 0 10px;
          box-decoration-break: clone;
        }
      }

      .content {
        position: relative;
        max-width: 1050px;
        margin-left: auto;
        padding: 72px 40px 72px 274px;
        background-color: $colorWhite;
        background-image: url(../../images/logo-sengager.png);
        background-position: left 47px center;
        background-size: 162px;
        background-repeat: no-repeat;
        font-size: 2rem;
        color: $colorBlack;

        @include sm {
          margin-top: 20px;
          padding: 40px 20px 40px 274px;
          font-size: 1.6rem;
        }

        @include xs {
          background-position: center top 20px;
          padding-left: 20px;
          padding-top: 130px;
          background-size: 80px;
        }

        h4 {
          margin: 0 0 20px;
          font-size: 2.4rem;
          color: $colorBlue;

          @include sm {
            font-size: 2rem;
          }
        }

        .content-cta {
          position: absolute;
          right: 76px;
          bottom: -34px;
          display: inline-block;
          padding: 0 50px;
          border-radius: 33px;
          box-shadow: 0 0 27px rgba($colorBlack, 0.14);
          line-height: 66px;
          font-size: 2.8rem;
          font-weight: bold;
          text-transform: uppercase;
          background-color: $colorYellow;
          @include transition;

          @include sm {
            padding: 0 30px;
            font-size: 2rem;
            line-height: 4rem;
            bottom: -20px;
          }

          @include xs {
            padding: 0 15px;
            font-size: 1.8rem;
            right: 20px;
          }

          &:hover {
            background-color: $colorBlue;
            color: $colorWhite;
          }
        }
      }
    }
  }
}

.campagne {
  .ladapt-read-more {
    display: inline-block;
    padding: 8px 20px;
    color: $colorBlue;
    font-size: 1.8rem;
    font-weight: bold;
    cursor: pointer;
    background-color: $colorWhite;
    border-radius: 18px;
    @include transition;
    &:hover {
      background-color: $colorDarkGray;
      color: $colorWhite;
    }
  }
}