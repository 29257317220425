footer {
  .section {
    max-width: 100%;
    padding: 0;
  }

  h2 {
    margin: 0;
  }
}

#block-ladapt-block-agir-ensemble {
  background-image: url(../../images/agir-ensemble-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  @include sm {
    background-attachment: scroll;
  }

  .content {
    padding: 100px 0;
    text-align: center;
    color: $colorWhite;

    @include sm {
      padding: 126px 20px 168px;
    }

    h2 {
      margin: 0;
      padding: 0;
      font-size: 7rem;
      text-transform: uppercase;
      color: $colorWhite;
      font-family: $fontRoboto;

      span {
        padding: 0 15px;
        background-color: $colorViolet;
        box-decoration-break: clone;
      }

      @include sm {
        font-size: 8rem;
      }

      @include xs {
        font-size: 5.5rem;
      }
    }

    h3 {
      display: inline-block;
      margin: 26px 0 0;
      padding: 18px 15px;
      background-color: $colorYellow;
      font-size: 7rem;
      line-height: 1;
      font-weight: 300;
      letter-spacing: letter-spacing(80);
      color: $colorBlue1;
      font-family: $fontRoboto;

      @include xs {
        font-size: 3.6rem;
      }
    }

    .menu {
      margin: 34px 0 0;
      text-align: center;
      font-size: 0;
      li {
        display: inline-block;
        margin: 0;
        background-color: $colorBlue;
        padding: 3px 0;

        &:after {
          content: "|";
          color: $colorYellow;
          font-weight: bold;
          font-size: 2.8rem;
          padding: 0 9px;
        }

        &.first {
          padding-left: 15px;
        }

        &.last {
          &:after {
            content: "";
            font-weight: bold;
            font-size: 2.8rem;
            padding: 0 9px;
          }
        }
      }
      a {
        color: $colorWhite;
        font-size: 2.8rem;
        line-height: 1;
        font-weight: bold;

        @include sm {
          font-size: 3.8rem;
        }

        @include xs {
          font-size: 2.8rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.block-simplenews {
  background: $colorLightBlue url(../../images/bg-newsletter.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0 90px;
  display: flex;
  justify-content: center;

  @include sm {
    background-image: none;
  }

  h2 {
    display: none;
  }

  .content {
    background-color: $colorWhite;
    background-image: url(../../images/icon-newsletter.png);
    background-position: left top;
    background-repeat: no-repeat;
    box-shadow: 0 0 38px rgba($colorBlack, 0.2);
    width: 83.33%;
    max-width: 1030px;
    height: 295px;
    padding: 48px 76px 58px 367px;
    font-size: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include md {
      width: calc(100% - 40px);
    }

    @include sm {
      display: block;
      height: auto;
      background-size: 228px;
      padding: 0 24px 36px;
    }

    @include max(540px) {
      background-size: 150px;
    }

    @include max(400px) {
      background-size: 100px;
    }

    .form-item-mail {
      display: inline;
    }

    h2 {
      display: block;
      margin: 0 0 24px;
      font-size: 3.6rem;
      font-weight: bold;
      color: $colorBlue;
      font-family: $fontRoboto;

      @include sm {
        padding-left: 260px;
        min-height: 230px;
        font-size: 4.2rem;
        display: flex;
        height: auto;
        align-items: center;
      }

      @include max(540px) {
        padding-left: 150px;
        font-size: 3.6rem;
        min-height: 0;
      }

      @include max(400px) {
        padding-left: 100px;
        font-size: 2.4rem;
      }
    }

    label {
      display: block !important;
      position: static !important;
      color: $colorBlue;
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 19px;
      opacity: 1 !important;
      margin-top: 2.4rem;

      @include sm {
        font-size: 3.4rem;
        margin-bottom: 10px;
      }

      @include xs {
        font-size: 2.4rem;
      }
    }

    input {
      border: 2px solid $colorLightGray2;
      border-width: 2px 0 2px 2px;
      font-size: 2rem;
      height: 52px;
      color: $colorBlack;
      display: inline-block;
      width: calc(100% - 167px);
      padding: 0 18px;
      margin-right: 0;
      @include transition;

      &:focus {
        border-color: $colorBlue;
      }

      @include sm {
        font-size: 3.2rem;
        height: 65px;
      }

      @include xs {
        width: 100%;
        border-width: 2px 2px 0 2px;
        font-size: 2rem;
        height: 52px;
      }

      &[type="button"],
      &[type="submit"] {
        @extend %yellow-button;
        padding: 0;
        min-width: 167px;
        height: 52px;
        width: auto;
        position: relative;

        &:hover {
          background-color: $colorBlue;
          color: $colorWhite;
        }

        @include sm {
          font-size: 2.5rem;
          height: 65px;
        }

        @include xs {
          font-size: 2.2rem;
          width: 100%;
          height: 52px;
        }
      }
    }

    form {
      &> div {
        position: relative;

        .captcha.form-wrapper {
          margin-top: 1rem;
          .field-prefix {
            font-size: 1.8rem;
            padding-left: 1rem;
          }

          input {
            border-width: 2px;
            width: 8rem !important;
            height: 4rem;
          }
        }

        @include min($md-width) {
          .captcha.form-wrapper {
            margin-top: 0;
            position: absolute;
            top: 45%;
            background-color: $colorWhite;
            width: 73.5rem;
            padding-left: 7.2rem;
            left: -7.2rem;

            .description {
              width: 40.7rem;
              height: 5rem;
              left: 16rem;
              bottom: -0.5rem;
              z-index: 1;
              font-size: 1.4rem;
              position: absolute;
              }
          }
        }
      }
    }
  }
}

#block-menu-menu-menu-footer {
  margin: 0;
  padding: 30px 0;
  background-color: $colorWhite;
  font-size: 0;

  ul {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    padding: 0 16px 0 0;

    @include xs {
      display: block;
    }

    &:before {
      content: "|";
      font-size: 2.4rem;
      display: inline-block;
      padding-right: 16px;
      color: $colorLightGray5;

      @include sm {
        font-size: 3.4rem;
      }

      @include xs {
        display: none;
      }
    }

    &:first-of-type {
      &:before {
        display: none;
      }
    }
  }

  a {
    font-size: 2.4rem;
    color: $colorBlue1;
    display: inline-flex;
    align-items: center;
    @include transition;

    @include sm {
      font-size: 3.4rem;
    }

    @include xs {
      font-size: 2.4rem;
    }

    &[href="/formulaire-nous-contacter.html"] {
      &:after {
        content: "";
        display: inline-block;
        background-image: url(../../images/icon-ear.png);
        background-position: center;
        background-repeat: no-repeat;
        width: 23px;
        height: 32px;
        margin-left: 10px;
      }
    }

    &:hover {
      color: $colorBlack;
    }
  }
}

#block-ladapt-block-footer-social {
  margin: 0;
  padding: 16px 0;
  background-color: $colorLightGray6;

  @include sm {
    padding: 35px 20px 0;
  }

  @include xs {
    padding: 20px 20px 10px;
  }

  .content {
    position: relative;
    @extend %clearfix;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include sm {
      width: 50%;
      float: right;
      justify-content: flex-start;
    }

    @include xs {
      max-width: 240px;
      width: calc(100% - 114px);
    }
  }

  li {
    display: flex;
    padding: 0 15px;

    @include sm {
      width: 50%;
      padding: 0;
      justify-content: center;

      &:nth-child(1),
      &:nth-child(2) {
        position: absolute;
        left: 0;
        top: 50%;
        width: auto;
      }

      &:nth-child(1) {
        margin-top: -47px;
      }

      &:nth-child(2) {
        margin-top: -30px;
        margin-left: 124px;
      }
    }

    @include xs {
      width: 100%;
      margin-bottom: 10px;
      justify-content: flex-start;

      &:nth-child(2) {
        display: none;
      }
    }

    .domain-sanslimites & {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .content a:not(.ladapt):not(.acce-o) {
    font-size: 1.6rem;
    color: $colorBlue2;
    text-align: center;
    letter-spacing: letter-spacing(80);
    margin: 0 0 0 35px;

    @include md {
      margin: 0 0 0 10px;
    }

    @include sm {
      margin: 0 0 35px 0;
      font-size: 2.4rem;
    }

    @include xs {
      display: flex;
      align-items: center;
      margin: 0;
    }

    .picto {
      width: 56px;
      height: 56px;
      background: $colorWhite;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 10px;
      @include transition;

      &:before {
        font-family: FontAwesome;
        color: $colorBlue2;
        font-size: 2.4rem;
      }

      @include sm {
        width: 75px;
        height: 75px;

        &:before {
          align-content: center;
          height: 75px;
          width: 75px;
          background-size: 75px 75px;
          font-size: 3.2rem;
        }
      }

      @include xs {
        width: 56px;
        height: 56px;
        margin: 0 10px 0 0;

        &:before {
          height: 56px;
          width: 56px;
          background-size: 56px 56px;
          font-size: 2.4rem;
        }
      }
    }

    &:hover {
      opacity: 0.6;
    }
  }

    .ladapt {
      font-size: 0;

      .picto {
        display: inline-block;
        background-color: transparent;
        background-image: url(../../images/logo.jpg);
        background-size: 94px;
        background-repeat: no-repeat;
        background-position: center;
        width: 94px;
        height: 94px;
      }
    }

    .acce-o {
      font-size: 0;

      .picto {
        display: inline-block;
        background-color: transparent;
        background-image: url(../../images/icon-acce-o.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 85px;
        height: 60px;
      }
    }

    .rogervoice {
      .picto {
        background: $colorBlue2 !important;
        &:before {
          color: $colorWhite !important;
        }
      }
    }

    .facebook {
      .picto {
        &,
        &:hover {
          background: transparent;
        }

        &::before {
          content: "";
          height: 56px;
          width: 56px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 56px 56px;
          display: block;
          background-image: url(../../images/icon-2024-facebook.png);
        }
      }
    }

    .bluesky {
      .picto {
        &,
        &:hover {
          background: transparent;
        }

        &::before {
          content: "";
          height: 56px;
          width: 56px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 76px 76px !important;
          display: block;
          background-image: url(../../images/icon-2024-bluesky.png);
        }
      }
    }

    .instagram {
      .picto {
        &,
        &:hover {
          background: transparent;
        }

        &::before {
          content: "";
          height: 56px;
          width: 56px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 56px 56px;
          display: block;
          background-image: url(../../images/icon-2024-instagram.png);
        }
      }
    }

    .linkedin {
      .picto {
        &,
        &:hover {
          background: transparent;
        }

        &::before {
          content: "";
          height: 56px;
          width: 56px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 56px 56px;
          display: block;
          background-image: url(../../images/icon-2024-linkedin.png);
        }
      }
    }

    .viadeo {
      .picto::before {
        //content: "";
      }
    }

  .youtube {
    .picto {
      &,
      &:hover {
        background: transparent;
      }

      &::before {
        content: "";
        height: 56px;
        width: 56px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 56px 56px;
        display: block;
        background-image: url(../../images/icon-2024-youtube.png);
      }
    }
  }
  .tiktok {
    .picto {
      &,
      &:hover {
        background: transparent;
      }

      &::before {
        content: "";
        height: 56px;
        width: 56px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 56px 56px;
        display: block;
        background-image: url(../../images/icon-2024-tiktok.png);
      }
    }
  }
    .rss {
      .picto::before {
        content: "\f167";
      }
    }
}

#block-ladapt-block-faire-un-don-footer {
  background-color: $colorViolet;
  background-image: url(../../images/bg-faire-un-don-footer.png);
  background-position: center bottom -20px;
  background-repeat: no-repeat;
  color: $colorWhite;
  height: 307px;

  @include sm {
    background-image: url(../../images/bg-faire-un-don-diagonal.png);
    background-position: right -212px bottom -205px;
    height: auto;
    padding: 60px 0;
  }

  .content {
    @extend %container;
    @extend %clearfix;
    text-align: center;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include sm {
      display: block;
    }
  }

  h2 {
    font-size: 4.2rem;
    font-weight: bold;
    width: 33.33%;
    color: $colorWhite;
    font-family: $fontRoboto;

    @include max(900px) {
      width: 50%;
    }

    @include sm {
      font-size: 5.2rem;
      width: 66.66%;
      margin: 0 auto;
    }

    @include xs {
      font-size: 4.2rem;
    }
  }

  h3 {
    margin: 0;
    background-color: $colorBlue;
    font-size: 3.4rem;
    font-weight: 300;
    padding: 10px 15px;
    width: 289px;
    height: 59px;
    margin-top: 25px;
    margin-left: calc((33% - 289px) / 2);
    letter-spacing: letter-spacing(110);
    color: $colorWhite;
    font-family: $fontRoboto;

    @include max(900px) {
      margin-left: calc((50% - 289px) / 2);
    }

    @include sm {
      width: 400px;
      height: 68px;
      font-size: 4.4rem;
      margin-left: 0;
      display: inline-block;
    }

    @include xs {
      width: auto;
      height: 59px;
      font-size: 3.4rem;
    }
  }

  .content a {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -39px;
    font-size: 3.5rem;
    font-weight: bold;
    display: inline-block;
    background: $colorWhite;
    color: $colorBlue;
    height: 78px;
    line-height: 78px;
    border-radius: 39px;
    letter-spacing: letter-spacing(20);
    width: 346px;
    box-shadow: 0 0 27px rgba($colorBlack, 0.14);
    @include transition;

    &:hover {
      background-color: $colorLightGray7;
      transform: scale(0.93);
      box-shadow: none;
    }

    @include sm {
      position: static;
      margin-top: 62px;
      font-size: 4.5rem;
      line-height: 100px;
      height: 100px;
      width: 446px;
    }

    @include xs {
      height: 78px;
      line-height: 78px;
      font-size: 3.5rem;
      width: auto;
      padding: 0 15px;
    }

    @include max(350px) {
      font-size: 3rem;
    }
  }
}

#block-ladapt-block-legal-footer {
  color: $colorWhite;

  .top {
    @extend %container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    font-weight: 500;
    @include fullWidthBackground($colorDarkBlue1);

    @include sm {
      height: auto;
      display: block;
      text-align: center;
      font-size: 2.6rem;
      font-weight: normal;

      span {
        display: block;
        padding: 30px 0 35px;
      }

      ul {
        text-align: center;
      }

      li {
        padding-bottom: 30px;
      }
    }

    @include xs {
      font-size: 1.6rem;
    }
  }

  .bot {
    @extend %container;
    display: flex;
    align-items: center;
    height: 38px;
    font-weight: 500;
    color: $colorLightBlue1;
    justify-content: flex-end;
    @include fullWidthBackground($colorDarkBlue2);

    @include sm {
      height: 77px;
      font-size: 2.6rem;
      justify-content: center;
    }

    @include xs {
      height: 38px;
      font-size: 1.6rem;
    }
  }

  ul.menu {
    margin: 0;
    padding: 0;
    font-size: 0;

    li {
      display: inline-block;

      @include xs {
        display: block;
      }

      &:before {
        content: "|";
        display: inline-block;
        padding: 0 6px;
        color: $colorBlue3;
        font-size: 1.6rem;

        @include sm {
          font-size: 2.6rem;
        }

        @include xs {
          display: none;
        }
      }

      &:first-of-type {
        &:before {
          display: none;
        }
      }

      a,
      a.active {
        font-size: 1.6rem;
        color: $colorWhite;

        &:hover {
          text-decoration: underline;
        }

        @include sm {
          font-size: 2.6rem;
          font-weight: 500;
        }

        @include xs {
          font-size: 1.6rem;
        }
      }
    }
  }
}
