.rte-3-col {
  overflow: hidden;
  > p {
    display: none;
  }
}
table {
  margin: 10px auto;
  border: 1px black solid;
  th, td {
    border: 1px black solid;
  }
  th, td {
    padding: 3px 5px;
  }
  th {
    background-color: #00a4e4;
    text-align: center;
  }
}
