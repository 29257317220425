////////////////////////
// Layouts Partials
//
// Separate your Layout from Design
// Build sub folders and partials in this folder, with everything being imported, in cascading order, into this file.
//
// All box model properties go here
//
// Override here the prime rules.
////////////////////////
@import 'wysiwyg';
@import 'header';
@import 'navigation';
@import 'search';
@import 'footer';
@import 'page-recherche';
@import 'actualite';
@import 'event';
@import 'homepage';
@import 'page-standard';
@import 'page-taxonomy';
@import 'etablissement';
@import 'partenaire';
@import 'entreprises_engagees';
@import 'webform';
@import 'homepage-sanslimites';
@import 'captcha';

.social {
  @include socials();
  display: flex!important;
  margin: 16px 0 62px;
}
