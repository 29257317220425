.page-search {
  #page-title {
    text-align: center;

    @include sm {
      text-align: left;
    }

    h1 {
      display: inline;
      line-height: 1.18;
      padding: 0 0 0 10px;

      .search-keywords {
        background-color: $colorWhite;
        color: $colorBlue1;
        padding-left: 10px;
        box-decoration-break: clone;
      }
    }
  }
}

#preface,
#postface {
  background-color: $colorLightGray3;
}

#preface {
  display: flex;
  align-items: center;
  height: 280px;
  margin-bottom: 75px;

  @include sm {
    height: auto;
    padding: 37px 0 51px;
  }

  .region-preface {
    @extend %clearfix;

    @include sm {
      display: flex;
      flex-direction: column;
    }
  }

  .results-count {
    width: 33.33%;
    float: left;
    height: 178px;
    color: $colorBlue1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include md {
      height: auto;
      margin-top: 10px;
    }

    @include sm {
      order: 2;
      width: 100%;
      margin-top: 30px;
      display: flex;
      align-items: center;
    }
  }

  .affiner-button {
    background-color: $colorBlue;
    color: $colorWhite;
    width: 377px;
    max-width: 100%;
    height: 89px;
    align-items: center;
    justify-content: center;
    font-size: 3.2rem;
    letter-spacing: letter-spacing(80);
    margin-bottom: 38px;
    display: none;

    @include sm {
      display: inline-flex;
    }
  }

  .counter {
    font-size: 3.5rem;
    font-weight: bold;
  }

  .results-page-info {
    font-size: 2.4rem;

    @include sm {
      display: none;
    }
  }

  .search-form,
  .item-list {
    width: 66.66%;
    float: right;
  }

  .search-form {
    margin-bottom: 0;

    @include sm {
      order: 1;
      width: 100%;
    }
  }

  .container-inline {
    font-size: 0;
  }

  label {
    width: 100%;
    display: block;
    font-size: 2rem;
    color: $colorBlack;
    margin-bottom: 23px;

    @include sm {
      font-size: 3rem;
      margin-bottom: 28px;
    }

    @include xs {
      font-size: 2rem;
      margin-bottom: 23px;
    }
  }

  input {
    width: calc(100% - 210px);
    background-color: $colorWhite;
    height: 50px;
    padding: 0 20px;
    font-size: 2rem;
    color: $colorBlack;
    border: 0;
    margin-right: 0;

    @include sm {
      width: calc(100% - 248px);
      height: 63px;
      font-size: 3rem;
    }

    @include xs {
      width: 100%;
      font-size: 2rem;
      height: 50px;
    }

    &#edit-submit {
      @extend %yellow-button;
      margin-left: 20px;

      @include sm {
        width: 228px;
        height: 63px;
        font-size: 3.2rem;
      }

      @include xs {
        width: 100%;
        margin: 0;
        height: 50px;
        font-size: 2.2rem;
      }
    }
  }

  .item-list {
    margin-top: 31px;

    @include md {
      width: 100%;
    }

    @include sm {
      margin-top: 35px;
      order: 3;
    }
  }
}

#postface {
  .item-list {
    height: 174px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .pager {
      max-width: 100%;

      @include sm {
        width: 100%;
      }
    }
  }
}

#content {
  .section {
    display: flex;

    #sidebar-second {
      flex-basis: 25%;
      border: 1px solid $colorLightGray4;
      border-width: 1px 1px 0 1px;
      padding-bottom: 20px;

      .region-sidebar-second {
        padding: 0 20px;

        @include sm {
          padding-bottom: 100px;
        }
      }

      @include md {
        flex-basis: 33.33%;
      }

      @include sm {
        position: fixed;
        width: 100vw;
        height: 0;
        visibility: hidden;
        top: 0;
        left: 0;
        z-index: 6;
        background-color: $colorWhite;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        opacity: 0;
        margin-top: -200px;
        @include transition;

        &.open {
          height: 100vh;
          margin-top: 0;
          opacity: 1;
          visibility: visible;
        }
      }

      select {
        height: 50px;

        @include sm {
          height: 100px;
        }

        @include xs {
          height: 50px;
        }
      }
    }

    #sidebar-second + #main-content {
      flex-basis: 75%;
      padding-left: 20px;

      @include md {
        flex-basis: 66.66%;
      }

      @include sm {
        flex-basis: 100%;
        padding-left: 0;
      }
    }
  }
}

#sidebar-second {
  .affiner-button {
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 2.8rem;
    color: $colorDarkGray1;
    letter-spacing: letter-spacing(80);
    height: 73px;
    position: relative;

    &:after {
      content: "";
      display: block;
      background-image: url(../../images/icon-remove@2x.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 49px;
      width: 49px;
      height: 49px;
      position: absolute;
      top: 50%;
      margin-top: -24.5px;
      right: 20px;
    }

    @include sm {
      display: flex;
    }
  }
}

#block-ladapt-solr-search-info {
  h2 {
    margin: 53px 0 45px;
    font-size: 2.7rem;
    font-weight: bold;
    color: $colorDarkGray1;

    @include sm {
      display: none;
    }
  }
}

#block-facetapi-giiy4zr9gu0zsa0bumw1y9qiipidf1wu {
  display: none;
}

.block-facetapi,
.block-ladapt-solr,
#block-ladapt-solr-facette-services-comites {
  margin: 0 -20px;

  h2 {
    align-items: center;
    background-color: $colorLightGray4;
    color: $colorBlue;
    display: flex;
    padding: 0 20px;
    font-size: 2.7rem;
    font-weight: normal;
    height: 60px;
    margin: 0;

    @include sm {
      margin: 1px 0;
      font-size: 3.7rem;
      height: 88px;

      &:after {
        content: "";
        display: inline-block;
        margin-left: auto;
        background-image: url(../../images/icon-next-blue@2x.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px;
        width: 25px;
        height: 39px;
        @include transition;
      }
    }

    @include xs {
      font-size: 2.7rem;
      height: 60px;

      &:after {
        background-size: 18px;
        width: 18px;
        height: 28px;
      }
    }
  }

  @include sm {
    .content {
      opacity: 0;
      display: none;
      @include transition;
    }

    &.open {
      h2 {
        &:after {
          transform: rotate(90deg);
        }
      }

      .content {
        display: block;
        opacity: 1;
      }
    }
  }

  &:nth-child(2) {
    @include sm {
      .content {
        display: block;
        opacity: 1;
      }

      h2 {
        &:after {
          display: none;
        }
      }
    }
  }
}

#ladapt-solr-select-facet-form {
  padding: 38px 20px 28px;

  select {
    width: 100%;
  }

  input[type="submit"] {
    display: none;
  }
}

.item-list {
  .facetapi-facetapi-links {
    li {
      list-style-type: none;
      list-style-image: none;
      margin: 0;
      line-height: 1.8;
      &.active {
        line-height: 1;
      }

      a {
        color: $colorDarkGray1;
        font-size: 1.7rem;
        font-weight: 500;
        @include transition(color);

        &:hover {
          color: $colorBlue;
        }

        @include sm {
          font-size: 2.7rem;
        }

        @include xs{
          font-size: 1.7rem;
        }
      }
    }
  }

  .facetapi-ladapt-solr-dropdowns,
  .facetapi-facetapi-links {
    margin: 29px 0;
    padding: 0 20px;

    li.active {
      background-color: transparent;
      margin: 0 0 12px;
      border: 3px solid $colorBlue;
      width: 100%;
      position: relative;
      height: 52px;
      display: flex;
      align-items: center;
      padding: 0 45px 0 15px;
      font-size: 1.7rem;
      font-weight: 500;
      color: $colorBlue;
      transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s;

      &:after {
        content: "";
        background-image: url(../../images/icon-close.png);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 99px;
        width: 33px;
        height: 33px;
        position: absolute;
        right: 6px;
        top: 6px;
      }

      &:hover {
        background-color: $colorBlue;
        color: $colorWhite;

        &:after {
          background-position: -66px 0;
        }
      }

      @include sm {
        font-size: 2.7rem;
        height: 100px;

        &:after {
          background-image: url(../../images/icon-close@2x.png);
          background-size: 147px;
          width: 49px;
          height: 49px;
        }

        &:hover {
          &:after {
            background-position: -98px 0;
          }
        }
      }

      @include xs {
        font-size: 1.7rem;
        height: 52px;

        &:after {
          background-size: 99px;
          width: 33px;
          height: 33px;
        }

        &:hover {
          &:after {
            background-position: -66px 0;
          }
        }
      }

      a.facetapi-active {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
      }
    }
  }
}

.facetapi-limit-link {
  @extend %blue-border-button;
  margin-left: 20px;

  @include sm {
    font-size: 2.7rem;
    height: 70px;
  }

  @include xs {
    font-size: 1.7rem;
    height: 44px;
  }

  &:before {
    content: "+";
    display: inline-flex;
    font-weight: 500;
    font-size: 3.5rem;
    margin-right: 10px;
    margin-top: -3px;

    @include sm {
      font-size: 4.5rem;
    }

    @include xs {
      font-size: 3.5rem;
    }
  }

  &.open {
    &:before {
      content: "-";
    }
  }
}

.search-result {
  background-color: $colorWhite;
  box-shadow: 0 0 18px rgba($colorBlack, 0.31);
  margin-bottom: 33px;
  @extend %clearfix;

  @include sm {
    margin-bottom: 40px;
  }

  .image-principale {
    width: 44%;
    float: left;
    font-size: 0;

    @include sm {
      width: 50%;
    }

    @include xs {
      width: 100%;
      float: none;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .titre-contenu {
    margin: 0;
    padding: 40px 20px 25px;
    color: $colorBlue1;
    font-size: 2.7rem;
    font-weight: bold;
    float: left;
    width: 56%;

    @include sm {
      width: 50%;
      padding: 13px 20px 25px;
      font-size: 3.7rem;
      word-wrap: break-word;
    }

    @include xs {
      width: 100%;
      float: none;
      font-size: 2.7rem;
    }
  }

  .chapo-contenu {
    padding: 0 20px 20px;
    color: $colorBlack;
    font-size: 1.8rem;
    float: right;
    width: 56%;

    @include sm {
      width: 50%;
      font-size: 2.8rem;
      word-wrap: break-word;
    }

    @include xs {
      width: 100%;
      float: none;
      font-size: 1.8rem;
    }
  }
}

.item-list {
  .pager {
    margin: 0;
    font-size: 0;
    display: flex;
    justify-content: flex-end;

    li {
      margin: 0 0 0 14px;
      padding: 0;
      display: flex;
      width: 50px;
      height: 50px;
      border: 2px solid $colorBlue;
      color: $colorBlue;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 2.7rem;
      @include transition;

      a {
        width: 100%;
        height: 100%;
        line-height: 46px;
        color: inherit;
      }

      &:first-of-type,
      &.first {
        margin-left: 0;
      }

      &.pager-current,
      &:hover {
        background-color: $colorBlue;
        color: $colorWhite;
      }

      &.pager-next,
      &.pager-last,
      &.pager-previous,
      &.pager-first {
        width: auto;
        font-size: 2.2rem;

        a {
          padding: 0 5px;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      &.pager-ellipsis,
      &.pager-mobile {
        width: auto;
        border: 0;
        padding: 0;

        &:hover {
          color: $colorBlue;
          background: transparent;
        }
      }

      &.pager-mobile {
        display: none;
      }

      @include sm {
        display: none;

        &.pager-previous,
        &.pager-next,
        &.pager-mobile {
          display: flex;
          margin: 0;
          width: 33.33%;
        }

        &.pager-previous {
          order: 1;
        }

        &.pager-mobile {
          order: 2;
          margin: 0 auto;
        }

        &:first-of-type.pager-mobile {
          margin: 0 auto 0 33.33%;
        }

        &.pager-current.last {
          display: block;
          width: 33.33%;
          text-indent: -9999px;
          order: 3;
          background: transparent;
          border: 0;
          margin: 0;
        }

        &.pager-next {
          order: 3;
        }
      }

      @include xs {
        &.pager-previous,
        &.pager-next {
          font-size: 0;
          color: transparent;
          position: relative;
          width: 80px;
          height: 80px;

          a {
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: block;
              background-image: url(../../images/icon-next-blue@2x.png);
              background-position: center;
              background-repeat: no-repeat;
              background-size: 25px;
            }

            &:hover {
              &:after {
                background-image: url(../../images/icon-next-white@2x.png);
              }
            }
          }
        }

        &.pager-previous {
          a {
            &:after {
              transform: rotate(180deg);
            }
          }
        }

        &.pager-mobile {
          width: auto;
          height: 80px;
        }

        &:first-of-type.pager-mobile {
          margin-left: 80px;
        }

        &.pager-current.last {
          width: 80px;
        }
      }
    }
  }
}

.page-agenda {
  overflow: hidden;

  .field-chapo {
    margin-top: 0;
  }

  .group-header {
    justify-content: center;
    min-height: 0;

    @include sm {
      padding: 32px 0 43px;
    }

    &:before {
      height: 90%;

      @include sm {
        height: 100%;
      }
    }

    .field-image-principale {
      margin-top: 32px;
      font-size: 0;

      @include sm {
        margin-top: 0;
      }

      picture {
        img {
          min-height: 0;
          box-shadow: 0 0 25px rgba($colorBlack, 0.2);
        }
      }
    }
  }

  #sidebar-second {
    width: 25%;
    .region-sidebar-second {
      .block-ladapt-solr {
        .content {
          margin-top: 1em;
          .form-item {
            margin-bottom: 0.5em;
            label {
              margin: 0 1em;
            }
          }
          input {
            &.form-text {
              margin: 1em;
              max-width: 75%;
            }
          }
          .form-submit {
            margin: 0 1em 1em;
          }
        }
      }
    }
  }

  .search-results {
    display: inline-grid;

    .search-result {
      grid-column: 1;
      margin-right: 1.5em;

      &:nth-child(2n) {
        grid-column: 2;
      }

      .titre-contenu {
        width: 100%;
      }

      .chapo-contenu {
        padding: 0 1em 0;
        width: 100%;
      }

      .dates, .thematiques {
        padding: 0 20px 0px;
      }
    }
  }
}
