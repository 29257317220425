.page-taxonomy {
  #page-title {
    display: none;
  }

  #main-content {
    padding-bottom: 0;

    h2 {
      font-family: $fontDroidSans;
      font-size: 4.5rem;
      font-weight: bold;
      letter-spacing: letter-spacing(20);
      color: $colorBlue1;
      padding: 7px 38px;
      margin: 0;
      text-align: center;
      display: inline-block;

      @include sm {
        padding: 11px 38px;
        font-size: 5.5rem;
      }

      @include xs {
        padding: 10px 10px;
        font-size: 4rem;
        word-break: break-all;
      }
    }
  }

  #block-views-territoires-block {
    text-align: center;
    padding: 27px 0 114px;
    @include fullWidthBackground($colorLightGray6);

    @include sm {
      padding: 34px 0 39px;
    }

    .content {
      text-align: left;
      margin-top: 38px;
    }
  }
}

#block-ladapt-block-territoire-landing-header {
  > .content {
    padding: 0 8.33%;

    @include sm {
      padding: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
    }
  }

  .field-visuel {
    text-align: center;
    margin-bottom: 50px;
    order: 1;
    @include fullWidthBackground($colorBlue5);

    @include sm {
      padding: 32px 0 43px;
    }

    &:before {
      height: 90%;

      @include sm {
        height: 100%;
      }
    }

    img {
      margin-top: 32px;
      width: 100%;
      height: auto;
      box-shadow: 0 0 25px rgba($colorBlack, 0.20);

      @include sm {
        margin-top: 0;
      }
    }
  }

  h1 {
    font-size: 0;
    font-family: $fontDroidSans;
    margin: 0 0 49px;
    order: 2;

    span {
      font-size: 5rem;

      @include sm {
        font-size: 6rem;
      }

      @include xs {
        font-size: 5rem;
      }
    }

    .head {
      display: block;
      color: $colorBlue1;
      padding: 0 19px 5px;
    }

    .name {
      padding: 0 19px;
      color: $colorWhite;
      background-color: $colorBlue1;
      box-decoration-break: clone;

      .gt {
        background-image: url(../../images/icon-gt.png);
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        width: 13px;
        height: 1.2em;
        vertical-align: bottom;

        @include sm {
          background-image: url(../../images/icon-gt@2x.png);
          background-size: 18px;
          width: 18px;
        }

        @include xs {
          background-size: 13px;
          width: 13px;
        }
      }
    }
  }

  .field-description-field,
  .taxonomy-term-description {
    order: 3;
  }
}

.field-description-field,
.taxonomy-term-description {
  padding: 52px 0 0 20%;
  margin: 0 0 61px;
  color: $colorBlack;
  @include fullWidthBackground($colorLightGray8);

  @include sm {
    padding: 0;
  }

  &:before {
    content: "";
    height: 20px;

    @include sm {
      display: none;
    }
  }

  p {
    margin-top: 0;
  }
}

#block-views-territoires-block {
  h2 {
    background-color: $colorGray3;
  }

  .slick-list {
    width: 100%;
    margin: 0;

    &:before,
    &:after {
      display: none;
    }
  }

  .slick-track {
    display: flex;

    @include max(600px) {
      margin-top: 127px;
    }

    @include xs {
      margin-top: 0;
    }

    @include max(430px) {
      margin-top: 127px;
    }

    .slick-slide{
      opacity: 0;
      visibility: hidden;
      height: auto;

      &[aria-hidden="false"] {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: -106px;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid $colorBlue1;
    width: 69px;
    height: 69px;
    text-indent: -9999px;
    cursor: pointer;
    @include transition;

    @include sm {
      top: -125px;
      background-size: 26px;
      width: 87px;
      height: 87px;
    }

    @include max(600px) {
      top: -107px;
    }

    @include xs {
      top: -106px;
      background-size: 21px;
      width: 69px;
      height: 69px;
    }

    @include max(430px) {
      top: -96px;
    }

    &:hover {
      background-color: $colorBlue1;
    }
  }

  .slick-next {
    background-image: url(../../images/icon-next-slide.png);
    right: 8px;

    &:hover {
      background-image: url(../../images/icon-next-slide-white.png);
    }

    @include sm {
      background-image: url(../../images/icon-next-slide@2x.png);

      &:hover {
        background-image: url(../../images/icon-next-slide-white@2x.png);
      }
    }

    @include max(600px) {
      right: 50%;
      margin-right: -97px;
    }

    @include xs {
      right: 8px;
      margin-right: 0;
    }

    @include max(430px) {
      right: 50%;
      margin-right: -79px;
    }
  }

  .slick-prev {
    background-image: url(../../images/icon-prev-slide.png);
    right: 113px;

    &:hover {
      background-image: url(../../images/icon-prev-slide-white.png);
    }

    @include sm {
      background-image: url(../../images/icon-prev-slide@2x.png);
      right: auto;
      left: 8px;

      &:hover {
        background-image: url(../../images/icon-prev-slide-white@2x.png);
      }
    }

    @include max(600px) {
      left: 50%;
      margin-left: -97px;
    }

    @include xs {
      left: 8px;
      margin-left: 0;
    }

    @include max(430px) {
      left: 50%;
      margin-left: -79px;
    }
  }

  .slick-disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  .slick-dots {
    display: flex;
    background-color: $colorLightGray12;
    margin: 107px 0 0 0;
    list-style: none;
    padding: 0;

    @include xs {
      margin-top: 39px;
    }

    li {
      display: block;
      height: 11px;
      padding: 0;
      text-indent: -9999px;
      width: 100%;
      @include transition;

      &:hover,
      &.slick-active {
        background-color: $colorDarkGray;
      }
    }
  }

  .views-row {
    margin: 0 10px;

    @include sm {
      margin: 0;
    }

    > a {
      width: 100%;

      @include sm {
        width: 50%;
      }

      @include xs {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}

#block-views-territoires-block-4 {
  text-align: center;
  padding: 46px 0 73px;

  h2 {
    background-color: $colorLightGray11;
  }

  .content {
    text-align: left;
    margin-top: 81px;
  }

  .views-row {
    box-shadow: 0 0 18px rgba($colorBlack, 0.31);
    margin-bottom: 34px;
    display: flex;
    align-items: center;

    @include sm {
      align-items: flex-start;
    }

    @include xs {
      flex-direction: column;
    }

    .field-image-principale,
    > a {
      font-size: 0;

      @include sm {
        width: 50%;
        flex: 1;
      }

      @include xs {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .group-right {
      flex: 1;
      padding: 20px;

      > a {
        display: block;
        color: $colorBlue1;
        font-size: 2.7rem;
        font-weight: bold;
        margin-bottom: 30px;

        @include sm {
          font-size: 3.7rem;
        }

        @include xs {
          font-size: 2.7rem;
        }
      }

      .services-lies {
        color: $colorBlack;

        strong {
          font-size: 2.2rem;
          display: block;
          margin-bottom: 20px;

          @include sm {
            font-size: 2.8rem;
          }

          @include xs {
            font-size: 2.2rem;
          }
        }

        dl {
          margin: 0;
          font-size: 0;
        }

        dt {
          display: inline-block;
          font-size: 1.6rem;

          @include sm {
            font-size: 2.7rem;
          }

          @include xs {
            font-size: 1.6rem;
          }

          &:after {
            content: "|";
            padding: 0 5px;
          }

          &:last-child {
            &:after {
              content: "";
            }
          }
        }
      }
    }
  }
}
