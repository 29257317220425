fieldset.captcha {
  span.field-prefix {
    margin-right: 1rem;
    line-height: 3.5rem;
  }

  input#edit-captcha-response {
    width: calc(60% - 8rem);

    @include sm {
      width: 60%;
    }
  }
  .description {
    flex: 1;
    margin-left: 2rem;
  }
}
