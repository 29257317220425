body {
  &.sticky {
    padding-top: 132px;
  }

  &.show-header,
  &.recherche-opened {
    header#header {
      display: block;
    }
  }
}

header#header {
  background-color: $colorWhite;
  height: 132px;
  width: 100%;
  top: 0;

  @include sm {
    position: relative;
    z-index: 3;
  }

  .section {
    align-items: center;
    display: flex;
    height: 100%;

    @include sm {
      padding-right: 0;
    }

    @include xs {
      padding-right: 20px;
    }
  }
}

#navigation {
  margin-right: 3.75%;
  width: 12.65625%;
  min-width: 150px;

  @include sm {
    margin-right: 0;
    width: 192px;
    min-width: 0;
  }

  @include xs {
    margin-right: 10px;
    width: 84px;
    justify-content: center;
    min-width: 84px;
  }
}

.hamburger {
  align-items: center;
  background-color: $colorWhite;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  z-index: 4;
  @include button($colorBlue, $height: 50px);
  @include transition;

  button {
    border: 0;
    background: transparent;
    color: inherit;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: letter-spacing(260);
    left: 0;
    padding: 0 0 0 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: left;
    text-transform: uppercase;
    top: 0;
  }

  .hamburger-icon {
    margin-right: 16px;
    float: right;

    span {
      display: block;
      background-color: $colorBlue;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      z-index: -1;

      @include transition($duration: 0.2s);


      &:nth-child(1) {
        width: 22px;
      }

      &:nth-child(2) {
        width: 29px;
      }

      &:nth-child(3) {
        margin-bottom: 0;
        width: 18px;
      }
    }
  }

  &:hover,
  &:focus {
    background-color: $colorBlue;
    color: $colorWhite;

    .hamburger-icon {
      span {
        background-color: $colorWhite;
      }
    }
  }

  &:active {
    background-color: $colorDarkBlue;
    border-color: $colorDarkBlue;
  }

  &.open {
    .hamburger-icon {
      margin-top: 9px;

      span {

        &:nth-child(1) {
          transform: rotate(45deg);
          width: 28px;
          margin-bottom: -5px;
        }

        &:nth-child(2) {
          opacity: 0;
          height: 0;
          margin: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          width: 28px;
        }
      }
    }
  }

  @include sm {
    order: 1;
    height: 84px;
    font-size: 3rem;

    button {
      letter-spacing: normal;
    }

    .hamburger-icon {
      span {
        height: 6px;
        margin-bottom: 7px;

        &:nth-child(1) {
          width: 34px;
        }

        &:nth-child(2) {
          width: 44px;
        }

        &:nth-child(3) {
          margin-bottom: 0;
          width: 28px;
        }
      }
    }

    &.open {
      .hamburger-icon {
        span {
          &:nth-child(1) {
            width: 44px;
            margin-bottom: -6px;
          }

          &:nth-child(3) {
            width: 44px;
          }
        }
      }
    }
  }

  @include xs {
    font-size: 0;
    color: transparent;
    justify-content: center;

    button {
      padding: 0;
    }

    .hamburger-icon {
      display: inline-block;
      margin-right: 0;
    }
  }
}

#recherche {
  width: 14.84375%;
  min-width: 170px;

  a {
    @include button($color: $colorDarkGray, $height: 50px);
    align-items: center;
    display: inline-flex;
    font-family: $fontRobotoCondensed;
    letter-spacing: letter-spacing(60);
    width: 100%;
    justify-content: center;
    text-transform: uppercase;
    @include transition;

    @include sm {
      font-size: 0;
      color: transparent;
      height: 84px;
    }

    &:after {
      content: "";
      display: inline-block;
      background-image: url(../../images/icon-search.png);
      background-position: 0 0;
      background-repeat: no-repeat;
      width: 21px;
      height: 21px;
      margin-left: 10px;

      @include sm {
        background-image: url(../../images/icon-search@2x.png);
        background-size: 70px;
        width: 35px;
        height: 35px;
        margin-left: 0;
      }
    }

    &:hover {
      background-color: $colorDarkGray;
      color: $colorWhite;

      &:after {
        background-position: -21px 0;

        @include sm {
          background-position: -35px 0;
        }
      }
    }

    &:active {
      background-color: $colorBlack1;
      border-color: $colorBlack1;
    }
  }

  @include sm {
    order: 3;
    width: 105px;
    min-width: 0;
    margin-right: 20px;
  }

  @include xs {
    margin-right: 0;
    min-width: 84px;
    width: 84px;
    margin-left: 10px;
  }
}

.nous-contacter {
  @include button($color: $colorDarkGray, $height: 50px);
  align-items: center;
  display: inline-flex;
  font-family: $fontRobotoCondensed;
  letter-spacing: letter-spacing(60);
  justify-content: center;
  text-transform: uppercase;
  @include transition;
  padding: 15px;
  margin: 0 3.75% 0 0;
  text-align: center;

  & + .faire-un-don {
    margin-left: 0;
  }

  @include sm {
    order: 2;
    font-size: 1.5rem;
    margin: 0 1% 0 0;
    letter-spacing: 0;
  }
}

#logo {
  font-size: 0;
  margin: 0 auto;
  flex-shrink: 0;

  img {
    height: 111px;
    width: auto;
    @include transition($duration: 0.2s);
  }

  @include sm {
    order: 2;
  }

  @include xs {
    flex-shrink: 1;
    margin: 0 auto;

    img {
      height: auto;
      max-height: 111px;
    }
  }
}

.faire-un-don {
  align-items: center;
  background-color: $colorViolet;
  background-image: url(../../images/bg-faire.svg);
  background-size: 143px;
  background-position: right -49px bottom -49px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 23.046875%;
  justify-content: center;
  margin-left: 8.2%;
  position: relative;
  z-index: 1;

  @include md {
    margin-left: 0;
    background-image: none;
  }

  @include sm {
    order: 4;
    width: 33.33%;

    .title {
      display: none;
    }
  }

  @include max(700px) {
    display: none;
  }

  .avec-ladapt {
    color: $colorWhite;
    display: inline-block;
    display: -ms-inline-flexbox;
    font-size: 2.6rem;
    font-family: $fontDroidSans;
    font-weight: bold;
    -ms-flex-direction: column;
    text-align: center;

    .hashtag {
      background-color: $colorBlue;
      display: inline-block;
      font-size: 2.2rem;
      font-family: $fontRoboto;
      font-weight: normal;
      letter-spacing: letter-spacing(50);
      margin-top: 5px;
      padding: 4px 8px;

      @include sm {
        padding: 2px 12px;
        font-size: 2.8rem;
      }
    }
  }

  a {
    @extend %white-radius-button;
    border-radius: 22px;
    font-size: 2rem;
    font-weight: bold;
    margin: 30px 0 -70px;
    width: 194px;
    height: 44px;

    @include sm {
      margin: 10px 0 0;
      width: 220px;
    }
  }
}


.recherche-opened {
  .faire-un-don-cta {
    margin: 0;

    @include sm {
      margin: 10px 0 0;
    }
  }

  #recherche {
    a:after {
      background-image: url(../../images/icon-x.png);
      background-size: 72px;
      background-position: 0 0;
      width: 36px;
      height: 36px;
      margin-left: 0;

      @include sm {
        background-image: url(../../images/icon-x@2x.png);
      }
    }

    &:hover {
      a:after {
        background-position: -36px 0;
      }
    }
  }
}

header#header {
  &.sticky {
    @extend %animated;
    @extend %sticky-menu-slide;
    height: 76px;
    position: fixed;
    opacity: 0;
    z-index: 3;

    &::before {
      content: "";
      display: none;
      width: 100%;
      height: 76px;
      background-color: rgba(0, 0, 0, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &.open::before {
      display: block;

      @include sm {
        display: none;
      }
    }

    @include sm {
      height: 110px;
      z-index: 3;
      display: none;
    }

    #logo {
      img {
        height: 67px;

        @include sm {
          height: 84px;
        }
      }
    }

    .avec-ladapt {
      height: 0;
      opacity: 0;
    }

    .faire-un-don a {
      margin: 0;
      position: relative;
    }
  }
}

#breadcrumb {
  background-color: $colorLightGray3;
  padding: 10px 0;

  .breadcrumb {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    color: $colorBlack;
    font-weight: bold;
    letter-spacing: letter-spacing(50);

    a {
      font-weight: 500;
    }

    .seperator {
      font-size: 0;
      color: transparent;
      background-image: url(../../images/breadcrumb-seperator.png);
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      width: 3px;
      height: 7px;
      padding: 0 10px;
    }
  }

  @include sm {
    display: none;
  }
}
