.slick-pause-text {
  display: none;
}

.group-diaporama {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;

  .slick-autoplay-toggle-button {
    display: none;
  }

  .slick-list {
    position: relative;

    &:before,
    &:after {
      content: "";
      background-color: rgba($colorDarkBlue4, 0.8);
      width: 120px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;

      @include sm {
        display: none;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .slick-dots {
    position: absolute;
    left: 0;
    top: calc(100% + 20px);
    bottom: auto;
    display: flex !important;
    height: 69px;
    align-items: center;
    margin: 0 !important;

    @include sm {
      display: none !important;
    }

    > li {
      width: 16px;
      height: 16px;
      font-size: 0 !important;
      margin: 0 10px 0 0 !important;

      &::before {
        display: none !important;
      }

      button {
        display: block;
        width: 16px;
        height: 16px;
        padding: 0;
        margin: 0;
        background-color: $colorBlue1;
        transition: all ease 0.4s;
      }

      &.slick-active {
        button {
          background-color: $colorYellow;
        }
      }
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto !important;
      background-color: $colorLightGray8;

      > div,
      .field-diaporama,
      figure,
      picture {
        height: 100%;
      }

      picture {
        display: flex;

        img {
          object-fit: contain;
        }
      }
    }
  }

  .field-diaporama {
    img {
      display: block;
      width: 100%;
    }
  }

  + .slider-controls {
    max-width: 930px;
    margin: 20px auto 40px;

    .slide-pager {
      margin-top: auto;
      display: flex;
      align-items: center;
    }

    .slide-pages {
      font-size: 5rem;
      color: $colorDarkGray1;
      font-family: $fontDroidSans;

      @include sm {
        font-size: 6rem;
      }

      @include max(380px) {
        display: none;
      }
    }

    .slide-controls {
      position: relative;
      list-style: none;
      width: auto !important;
      margin: 0 0 0 auto;
      font-size: 0;
      padding: 0;
      z-index: 1;

      @include sm {
        margin: 0 auto;
      }

      li {
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        border: 2px solid $colorBlue;
        width: 69px;
        height: 69px;
        margin: 0 14px 0 0;
        cursor: pointer;
        @include transition;

        &::before {
          display: none;
        }

        @include sm {
          width: 80px;
          height: 80px;
        }

        @include xs {
          width: 69px;
          height: 69px;
        }

        &:hover {
          background-color: $colorBlue;
        }

        &:last-child {
          margin-right: 0;
        }

        &.prev {
          background-image: url(../../images/icon-prev-slide.png);

          &:hover {
            background-image: url(../../images/icon-prev-slide-white.png);
          }
        }

        &.pause {
          background-image: url(../../images/icon-pause-slide.png);

          &:hover {
            background-image: url(../../images/icon-pause-slide-white.png);
          }

          &.paused {
            background-image: url(../../images/icon-pause-slide-white.png);
            background-color: $colorBlue;
          }
        }

        &.next {
          background-image: url(../../images/icon-next-slide.png);

          &:hover {
            background-image: url(../../images/icon-next-slide-white.png);
          }
        }
      }
    }
  }
}
