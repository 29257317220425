.node-type-page {
  #page-title {
    display: none;
  }

  #main-content {
    padding-bottom: 0;
  }

  h1 {
    width: 50%;
    font-family: $fontDroidSans;
    font-size: 5rem;
    font-weight: bold;
    margin: 40px 0 30px 8.33%;

    span {
      background-color: $colorBlue1;
      color: $colorWhite;
      padding: 1px 30px 1px 15px;
      box-decoration-break: clone;
    }

    @include sm {
      width: 100%;
      margin: 35px 0;
      font-size: 6rem;
    }

    @include xs {
      font-size: 5rem;
    }
  }
}

.group-meme-sujet {
  margin-top: 73px;
  padding: 80px 0 140px;
  @include fullWidthBackground($colorBlue);

  @include sm {
    padding: 53px 0 79px;
  }

  h3 {
    text-align: center;
    color: $colorWhite;
    font-size: 5rem;
    font-weight: bold;
    margin: 0 0 80px;

    @include sm {
      font-size: 6rem;
    }

    @include xs {
      font-size: 5rem;
    }
  }
}

.view-meme-sujet-article {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include sm {
      margin: 0;
    }
  }

  .views-row {
    background-color: $colorWhite;
    width: calc(50% - 20px);
    margin: 0 10px 37px;
    @include transition;

    &:hover {
      box-shadow: 0 0 32px rgba($colorBlack, 0.83);
    }

    @include sm {
      width: 100%;
      margin: 0 0 54px;
    }

    img {
      width: 100%;
      height: auto;
    }

    .views-field-title {
      font-size: 2.7rem;
      font-weight: bold;
      color: $colorBlue1;
      margin: 27px 0 15px;
      padding: 0 20px;

      @include sm {
        font-size: 3.7rem;
      }

      @include xs {
        font-size: 2.7rem;
      }
    }

    .field-chapo {
      font-weight: normal;
      margin: 0 0 33px;
      padding: 0 20px;
    }
  }
}

.node {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    $table_class-list: bleu violet vert bleu-ciel;
    $table_color-list: #1c3e86 #a21c75 #006363 #466593;

    @each $table_class in $table_class-list {
      $i: index($table_class-list, $table_class);
      &.#{$table_class} {
        th {
          color: nth($table_color-list, $i);
          background-color: transparent;
          border: 2px solid nth($table_color-list, $i);;
        }


        td {
          border: 2px solid nth($table_color-list, $i);;
        }
      }
    }

    th {
      color: nth($table_color-list, 1);
      background-color: transparent;
      border: 2px solid nth($table_color-list, 1);
      text-align: left;
      padding: 10px;
    }

    thead th {
      text-align: center;
      background-color: #f2f2f2;
    }

    td {
      padding: 10px;
      border: 2px solid nth($table_color-list, 1);
    }

    @include sm {
      border: 0;
      min-width: 80%;
      thead {
        display: none;
      }
      tbody {
        border: 0;
      }
      tr {
        display: block;
        margin-bottom: 10px;
      }
      th {
        border: 0;
      }

      td {
        display: block;
        text-align: right;
        &::before {
          content: attr(data-label);
          float: left;
        }
        &:not(:last-child) {
          border-bottom: 0;
        }
      }
    }
  }
}